import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import "@inovua/reactdatagrid-community/theme/amber-light.css";
import "@inovua/reactdatagrid-community/theme/blue-dark.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import {
  handleServerError,
  ReactDataGrid_i18n,
  showError
} from "../components/common";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const fieldLabels = englishUI
  ? {
    FormTitle: "Contractor Current Evalution"
  }
  : {
    FormTitle: "Chấm điểm nhà thầu hiện tại"
  };
const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnThoat: "Close",
    btnImport: "Import"
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnThoat: "Thoát",
    btnImport: "Nhập file"
  };
const default_gridColumns = [
  {
    name: "STT",
    type: "number",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "No" : "STT",
  },
  {
    name: "TenCongTy",
    type: "string",
    defaultFlex: 1,
    headerAlign: "center",
    header: englishUI ? "Shipyard/Sub-Contractor" : "Nhà thầu"
  },
  {
    name: "AcceptPoint",
    type: "number",
    defaultWidth: 150,
    headerAlign: "center",
    header: "Accept Point",
  },
  {
    name: "Approved",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: "Approved",
    render: ({ data }) => data.Approved ? "Yes" : "No"
  },
  {
    name: "TotalPoint",
    type: "number",
    defaultWidth: 150,
    headerAlign: "center",
    header: "Total Point",
  },
  {
    name: "Remark",
    type: "string",
    defaultFlex: 1,
    headerAlign: "center",
    header: englishUI ? "Remark" : "Ghi chú"
  },
  {
    name: "NgayCN",
    type: "string",
    defaultWidth: 140,
    headerAlign: "center",
    header: "Update time",
    render: ({ data }) =>
      data.NgayCN ? moment(data.NgayCN).format("DD/MM/YYYY HH:mm:ss") : null,
  }
];

////////////////////////////////////////////////////////////////////////////
export default class ContractorCurrentEvaluationPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click

  constructor(props) {
    super(props);

    this.state = {
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      saving: false, //co dang save du lieu hay khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
    };
  }

  componentDidMount() {
    this.doSearch();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  handleClose(selection) {
    this.props.close(selection);
  }

  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData();
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  loadData = () => {
    var loader = new Promise((resolve, eject) => {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_ChamDiemNam_Get",
          ThamSo: {
            Nam: 0
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.CP_ChamDiemNam,
              count: response.CP_ChamDiemNam.length
            }
          });
          resolve({
            data: response.CP_ChamDiemNam,
            count: response.CP_ChamDiemNam.length
          });
        })
        .catch((error) => {
          handleServerError(error);
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };

  doImport = (file) => {
    try {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const json = {
          "FileName": file.name,
          "Content": Buffer.from(text).toString("base64")
        };
        this.doUpload(json);
      };
      fr.onerror = (err) => showError(err);
      fr.readAsArrayBuffer(file);
    } catch (ex) {
      showError(ex);
    }
  };

  //upload du lieu report
  doUpload(json) {
    this.setState({ saving: true });
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_ChamDiemNam_ImportExcel",
        ThamSo: json
      })
      .then(response => server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_ChamDiemNam_Update",
          ThamSo: response.CP_ChamDiemNam.map(item => {
            return {
              ...item,
              Nam: 0
            }
          })
        }))
      .then(() => {
        this.doSearch();
        toast.success("Saved data successfully.");
        this.setState({ saving: false });
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AppBar sx={{ position: "relative" }}>
            <Stack direction="row">
              <Typography
                sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
                variant="h6"
                component="div"
              >
                {fieldLabels.FormTitle}
              </Typography>
              <Stack direction="row" spacing={4}>
                {this.state.loading_data ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.saving ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                <Dropzone
                  maxSize={2 * 1024 * 1024}
                  multiple={false}
                  accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], "application/vnd.ms-excel": [] }}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.doImport(acceptedFiles[0]);
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()} style={{ height: "100%" }}>
                        <input {...getInputProps()} />
                        <Button color="success" variant="contained" style={{ height: "100%" }}>
                          {buttonLabels.btnImport}
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.handleClose(undefined)}
                >
                  {buttonLabels.btnThoat}
                </Button>
              </Stack>
            </Stack>
          </AppBar>
          <ReactDataGrid
            style={{
              height: 400,
              fontSize: 12,
              marginTop: 5
            }}
            showZebraRows={true}
            i18n={englishUI ? undefined : ReactDataGrid_i18n}
            columns={default_gridColumns}
            pagination={false}
            dataSource={this.state.search_dataSource}
            idProperty="STT"
            minRowHeight={30}
          ></ReactDataGrid>
        </DialogContent>
      </Dialog>
    );
  }
}
