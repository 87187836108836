import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField
} from "@mui/material";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  loadDataError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import NumericFormat from "react-number-format";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import CostPlanEditDialog from "./CostPlanEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    Nam: "Year:"
  }
  : {
    Nam: "Năm:"
  };

const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnImport: "Import"
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnImport: "Nhập"
  };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "ShipName",
    type: "string",
    headerAlign: "center",
    header: "Tàu",
    render: ({ data }) => data.ShipName
  },
  {
    name: "SoNgay",
    type: "string",
    headerAlign: "center",
    header: "Số ngày",
    render: ({ data }) => data.SoNgay
  },
  {
    name: "DinhKy_SuaChua",
    type: "number",
    headerAlign: "center",
    header: "Docking",
    group: "SuaChuaTrenDa",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DinhKy_SuaChua ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "DinhKy_Son",
    type: "number",
    headerAlign: "center",
    header: "Sơn",
    group: "SuaChuaTrenDa",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DinhKy_Son ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "DinhKy_DangKiem",
    type: "number",
    headerAlign: "center",
    header: "Đăng kiểm",
    group: "SuaChuaTrenDa",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DinhKy_DangKiem ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "DinhKy_Tong",
    type: "number",
    headerAlign: "center",
    header: "Tổng",
    group: "SuaChuaTrenDa",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DinhKy_Tong ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "QD_Moi",
    type: "string",
    headerAlign: "center",
    header: "New IMO",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.QD_Moi ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "DauBen_SuaChua",
    type: "number",
    headerAlign: "center",
    header: "Docking",
    group: "SuaChuaDauBen",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DauBen_SuaChua ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "DauBen_Son",
    type: "number",
    headerAlign: "center",
    header: "Sơn",
    group: "SuaChuaDauBen",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DauBen_Son ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "DauBen_DangKiem",
    type: "number",
    headerAlign: "center",
    header: "Đăng kiểm",
    group: "SuaChuaDauBen",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DauBen_DangKiem ?? ""} displayType="text" thousandSeparator="," />
  },
  {
    name: "DauBen_Tong",
    type: "number",
    headerAlign: "center",
    header: "Tổng",
    group: "SuaChuaDauBen",
    textAlign: "end",
    render: ({ data }) => <NumericFormat value={data.DauBen_Tong ?? ""} displayType="text" thousandSeparator="," />
  }
];
export const gridColumns_groups = [
  { name: "SuaChuaTrenDa", header: "SỬA CHỮA TRÊN ĐÀ (VNĐ)", headerAlign: "center" },
  { name: "SuaChuaDauBen", header: "SỬA CHỮA SỬA CHỮA ĐẦU BẾN (VNĐ)", headerAlign: "center" }
];
const summaryReducer = {
  initialValue: {
    DinhKy_SuaChua: 0,
    DinhKy_Son: 0,
    DinhKy_DangKiem: 0,
    DinhKy_Tong: 0,
    QD_Moi: 0,
    DauBen_SuaChua: 0,
    DauBen_Son: 0,
    DauBen_DangKiem: 0,
    DauBen_Tong: 0
  },
  reducer: (accumulator, item) => {
    if (item.DinhKy_SuaChua) {
      accumulator.DinhKy_SuaChua += item.DinhKy_SuaChua
    }
    if (item.DinhKy_Son) {
      accumulator.DinhKy_Son += item.DinhKy_Son
    }
    if (item.DinhKy_DangKiem) {
      accumulator.DinhKy_DangKiem += item.DinhKy_DangKiem
    }
    if (item.DinhKy_Tong) {
      accumulator.DinhKy_Tong += item.DinhKy_Tong
    }
    if (item.QD_Moi) {
      accumulator.QD_Moi += item.QD_Moi
    }
    if (item.DauBen_SuaChua) {
      accumulator.DauBen_SuaChua += item.DauBen_SuaChua
    }
    if (item.DauBen_Son) {
      accumulator.DauBen_Son += item.DauBen_Son
    }
    if (item.DauBen_DangKiem) {
      accumulator.DauBen_DangKiem += item.DauBen_DangKiem
    }
    if (item.DauBen_Tong) {
      accumulator.DauBen_Tong += item.DauBen_Tong
    }

    return accumulator
  }
};
const footerRows = [{
  position: 'end',
  cellStyle: {
    'justify-content': 'end'
  },
  render: {
    ShipName: () => <b>Tổng</b>,
    DinhKy_SuaChua: ({ summary }) => <NumericFormat value={summary.DinhKy_SuaChua ?? ""} displayType="text" thousandSeparator="," />,
    DinhKy_Son: ({ summary }) => <NumericFormat value={summary.DinhKy_Son ?? ""} displayType="text" thousandSeparator="," />,
    DinhKy_DangKiem: ({ summary }) => <NumericFormat value={summary.DinhKy_DangKiem ?? ""} displayType="text" thousandSeparator="," />,
    DinhKy_Tong: ({ summary }) => <NumericFormat value={summary.DinhKy_Tong ?? ""} displayType="text" thousandSeparator="," />,
    QD_Moi: ({ summary }) => <NumericFormat value={summary.QD_Moi ?? ""} displayType="text" thousandSeparator="," />,
    DauBen_SuaChua: ({ summary }) => <NumericFormat value={summary.DauBen_SuaChua ?? ""} displayType="text" thousandSeparator="," />,
    DauBen_Son: ({ summary }) => <NumericFormat value={summary.DauBen_Son ?? ""} displayType="text" thousandSeparator="," />,
    DauBen_DangKiem: ({ summary }) => <NumericFormat value={summary.DauBen_DangKiem ?? ""} displayType="text" thousandSeparator="," />,
    DauBen_Tong: ({ summary }) => <NumericFormat value={summary.DauBen_Tong ?? ""} displayType="text" thousandSeparator="," />,
  }
}];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "Code", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor

//ham filter su dung cho combobox

class CostPlanPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {}, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            //danh sach cac tieu chi tim kiem
            Nam: new Date().getFullYear()
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      hasQuyen: global.hasRoles("R151")
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setMyState(this.state);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() { }
  loadData = () => {

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_KeHoachNam_Get",
          ThamSo: {
            ...this.state.after
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.CP_KeHoachNam,
              count: response.CP_KeHoachNam.length,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined
          });
          resolve({
            data: response.CP_KeHoachNam,
            count: response.CP_KeHoachNam.length,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData();
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.Nam}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 2020, max: new Date().getFullYear() } }}
                value={this.state.after.Nam}
                onChange={(e) => {
                  this.saveField("Nam", +e.target.value);
                }} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              groups={gridColumns_groups}
              summaryReducer={summaryReducer}
              footerRows={footerRows}
              showHoverRows={false}
              dataSource={this.state.search_dataSource}
              idProperty="ShipCode"
              rowHeight={30}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              sortable={false}
            ></ReactDataGrid>
          </Grid>
          {this.state.hasQuyen && <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      open_import: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnImport}
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        {this.state.open_import ? (
          <CostPlanEditDialog
            open={true}
            close={(saved) => {
              this.setState({ open_import: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(CostPlanPage);
