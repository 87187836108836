import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  DialogTitle
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import React from "react";
import "react-toastify/dist/ReactToastify.css";

//////////////////////////////////////////////---------------///////////////////////////////////////////////////////
export default class ModuleSelectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  onClose() {
    this.props.close();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{
          paddingLeft: "12px"
        }}>
          Select module
          <IconButton onClick={() => this.onClose()} sx={{
            position: 'absolute',
            top: "-5px",
            right: 0
          }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={12}>
                <Button
                  color={"success"}
                  variant="contained"
                  fullWidth
                  href="https://office.vosco.vietms.vn/"
                >
                  Noon Report
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color={"primary"}
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    this.props.close();
                  }}
                >
                  Quản lý chi phí
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
