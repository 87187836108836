/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "ME. POWER EXPRESSION"
  : "CÔNG THỨC TÍNH CÔNG SUẤT MÁY CHÍNH";
const fieldLabels = englishUI
  ? {
      ExpressionCode: "Code:",
      Script: "Script:",
      Rpm: "RPM:",
      BCA: "BCA:",
      TempOil: "FM. Temp:",
      TempAir: "Temp Air:",
      TempRoom: "Temp Room:",
      TestTitle: "Try to calculate power:",
      PowerResult: "ME. Power =",
    }
  : {
      ExpressionCode: "Mã số:",
      Script: "Các lệnh tính toán:",
      Rpm: "Vòng quay:",
      BCA: "BCA:",
      TempOil: "Nhiệt độ dầu:",
      TempAir: "Temp Air:",
      TestTitle: "Thử tính công suất:",
      PowerResult: "Công suất =",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
      btnTest: "Try",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
      btnTest: "Thử tính",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["ExpressionCode", "Script"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
////////////////////////--------------/////////////////////////////////////////
export default class ExpressionMEEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        ExpressionCode: props.edittingRecord.ShipCode, //lay bang shipcode luon
        Script: "",
        //gia tri tinh thu
        Rpm: 100,
        BCA: 70,
        TempOil: 90,
        TempAir: 45,
        TempRoom: 30,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      Logs: "",
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //load du lieu khi form load
  loadDanhMuc() {
    if (this.props.edittingRecord !== undefined) {
      this.setState({ saving: true });
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_DM_Expression_GetOne",
          ThamSo: {
            ExpressionCode: this.state.after.ExpressionCode,
          },
        })
        .then((response) => {
          //du lieu cua 1 do thi
          const list = response.DM_Expression;
          this.state.after.Script = list.length > 0 ? list[0].Script : "";
          this.setState({ saving: false, Logs: '' });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    }
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.ShipID > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_DM_Expression_Update",
            ThamSo: this.state.after,
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved

            alert("Cập nhật thành công!");
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };
  //thuc hien thu gia tri
  doTest = () => {
    try {
      this.setState({ saving: true, errorLog: "" });
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_DM_Expression_GetTest",
          ThamSo: {
            ExpressionCode: this.state.after.ExpressionCode,
            Rpm: this.state.after.Rpm,
            BCA: this.state.after.BCA,
            TempOil: this.state.after.TempOil,
            TempAir: this.state.after.TempAir,
            TempRoom: this.state.after.TempRoom,
          },
        })
        .then((response) => {
          //lay ket qua neu co
          this.state.after.PowerResult =
            response.DM_Expression_Result[0].PowerResult;
            const logs = response.Logs[0];

          this.setState({
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
            Logs: JSON.stringify(logs, null, 4),
          });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    } catch (ex) {
      showError(ex);
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.ExpressionCode}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtExpressionCode"
                  fullWidth
                  readOnly
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 20,
                  }}
                  value={this.state.after.ExpressionCode}
                  onChange={(event) => {
                    this.saveField(
                      "ExpressionCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.ExpressionCode !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.Script}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtScript"
                  fullWidth
                  multiline
                  autoFocus
                  inputProps={{
                    style: { overflow: "scroll", height: 300 },

                    maxLength: 4000,
                  }}
                  value={this.state.after.Script}
                  onChange={(event) => {
                    this.saveField("Script", event.target.value);
                  }}
                  error={this.state.validateStatus.Script !== undefined}
                />
              </Grid>
              {this.props.edittingRecord !== undefined ? (
                <>
                  <Grid item xs={24}>
                    <Typography variant="h6">
                      {fieldLabels.TestTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.Rpm}</FormLabel>
                  </Grid>

                  <Grid item xs={4}>
                    <MyNumberEditor
                      id="Rpm"
                      fullWidth
                      decimalPlaces={4}
                      value={this.state.after.Rpm}
                      onValueChange={(value) => {
                        this.saveField("Rpm", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.BCA}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyNumberEditor
                      id="BCA"
                      fullWidth
                      value={this.state.after.BCA}
                      onValueChange={(value) => {
                        this.saveField("BCA", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.TempOil}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyNumberEditor
                      id="TempOil"
                      fullWidth
                      value={this.state.after.TempOil}
                      onValueChange={(value) => {
                        this.saveField("TempOil", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.TempAir}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyNumberEditor
                      id="TempAir"
                      fullWidth
                      value={this.state.after.TempAir}
                      onValueChange={(value) => {
                        this.saveField("TempAir", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.TempRoom}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyNumberEditor
                      id="TempRoom"
                      fullWidth
                      value={this.state.after.TempRoom}
                      onValueChange={(value) => {
                        this.saveField("TempRoom", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>{fieldLabels.PowerResult}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyNumberEditor
                      id="PowerResult"
                      fullWidth
                      readOnly
                      value={this.state.after.PowerResult}
                      onValueChange={(value) => {
                        this.saveField("PowerResult", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={24}>
                    <OutlinedInput
                      id="PowerResult"
                      fullWidth
                      readOnly
                      multiline
                      inputProps={{
                    style: { overflow: "scroll", height: 300 },

                    maxLength: 4000,
                  }}
                      value={this.state.Logs}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>
          {this.props.edittingRecord !== undefined ? (
            <Button
              onClick={() => {
                this.doTest();
              }}
              variant="contained"
            >
              {buttonLabels.btnTest}
            </Button>
          ) : null}
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
