import {
  AppBar,
  Button,
  Dialog,
  FormLabel,
  Grid,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError,
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import NumericFormat from "react-number-format";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import ExtraBillEditPage from "./ExtraBillEditPage";
import ExtraBillInvoiceEditPage from "./ExtraBillInvoiceEditPage";
import PODetailDialog from "./PODetail/PODetailDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    SoQuyetToan: "Bill No:",
    TenCongTy: "Company:",
    Nam: "Year:",
    IsKetThuc: "Status:",
    IsDocking: "Docking:",
  }
  : {
    SoQuyetToan: "Số QT:",
    TenCongTy: "Công ty:",
    Nam: "Năm:",
    IsKetThuc: "Trạng thái:",
    Docking: "Docking:",
  };
const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnThem: "Add",
    btnSua: "Edit",
    btnInvoice: "Invoice",
    btnDelete: "Delete"
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnThem: "Thêm",
    btnSua: "Edit",
    btnInvoice: "Hóa đơn",
    btnDelete: "Xóa"
  };
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "MaYeuCau", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox
const columns_grid = [
  {
    name: "ID",
    type: "string",
    defaultWidth: 60,
    headerAlign: "center",
    header: "ID",
    render: ({ data }) => `E${data.QuyetToanID}`
  },
  {
    name: "SoQuyetToan",
    type: "string",
    defaultWidth: 140,
    headerAlign: "center",
    header: englishUI ? "Bill No" : "HMSC",
  },
  {
    name: "NgayQuyetToan",
    type: "string",
    defaultWidth: 90,
    headerAlign: "center",
    header: englishUI ? "Date" : "Ngày yêu cầu",
    render: ({ data }) =>
      data.NgayQuyetToan
        ? moment(data.NgayQuyetToan).format("DD/MM/YYYY")
        : null,
  },
  {
    name: "IsDocking",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: "Docking",
    render: ({ data }) => (data.IsDocking ? "Docking" : "Normal"),
  },
  {
    name: "TenLoaiHinhQT",
    type: "string",
    defaultWidth: 150,
    headerAlign: "center",
    header: "Cost type",
    render: ({ data }) => (data.TenLoaiHinhQT ? data.TenLoaiHinhQT : data.LoaiHinhQT == 0 ? "Chi khác" : ""),
  },
  {
    name: "TruocThue_QT",
    type: "string",
    defaultWidth: 110,
    headerAlign: "center",
    header: "Cost",
    textAlign: "right",
    render: ({ data }) => (
      <NumericFormat
        value={data.TruocThue_QT ?? ""}
        displayType="text"
        thousandSeparator=","
      />
    ),
  },
  {
    name: "TenCongTy",
    type: "string",
    defaultWidth: 200,
    headerAlign: "center",
    header: "Company",
  },
  {
    name: "FileQuyetToan",
    type: "string",
    defaultWidth: 200,
    headerAlign: "center",
    header: "File",
    render: ({ data }) => (
      <Link
        href={server.getServer() + "api/ChiPhi/GetFile?fileID=" + data.FileID}
        target="_blank"
      >
        {data.FileQuyetToan}
      </Link>
    ),
  },
  {
    name: "Attach",
    type: "string",
    defaultWidth: 150,
    headerAlign: "center",
    header: "Document",
    render: ({ data }) => {
      try {
        let attachs = JSON.parse(data.Attachs);

        if (Array.isArray(attachs) && attachs.length > 0 && attachs[0].FileID) {
          return <Link
            href={server.getServer() + "api/ChiPhi/GetFile?fileID=" + attachs[0].FileID}
            target="_blank"
          >
            {attachs[0].FileName}
          </Link>;
        }
      } catch (ex) {
        console.warn(ex);
        return <></>;
      }
    }
  },
  {
    name: "BienBan",
    type: "string",
    defaultWidth: 200,
    headerAlign: "center",
    header: "Note",
  },

  {
    name: "NgayCN",
    type: "string",
    defaultWidth: 140,
    headerAlign: "center",
    header: "Update time",
    render: ({ data }) =>
      data.NgayCN ? moment(data.NgayCN).format("DD/MM/YYYY HH:mm:ss") : null,
  },
];
class ExtraBillPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);

    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [
          { value: 0, label: "Doing" },
          { value: 1, label: "Completed" },
        ],
        DM_Docking: [
          { value: 0, label: "No" },
          { value: 1, label: "Yes" },
        ],
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            PageIndex: 1,
            PageSize: 25,
            Ascending: true,
            SortBy: getSortColumnIndex(columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
            //danh sach cac tieu chi tim kiem
            HangMucID: 0, //tim kiem quyet toan phat sinh nen mac dinh la 1
            IsDocking: null,
            SoQuyetToan: null,
            TenCongTy: null,
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      showUploadForm: false, //hien thi form upload chao gia
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_QuyetToanSC_Search",
          ThamSo: {
            ...this.state.after,
            ShipID: this.props.ShipData.ShipID,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  getFileID() {
    try {
      let dinhKem = JSON.parse(this.state.rowSelected.DinhKem);
      return Array.isArray(dinhKem) && dinhKem.length > 0 ? dinhKem[0] : null;
    } catch (ex) {
      console.warn(ex);
    }

    return null;
  }

  doExport(exportFormat, language) {
    server
      .post("Chiphi/DoRequest", {
        Function: "Proc_CP_PhieuGV_Export",
        ThamSo: {
          HangMucID: this.state.rowSelected.HangMucID,
          ExportFormat: exportFormat,
          Form: language,
          Authorization: server.getToken(),
        },
      })
      .then((response) => {
        const decoded = Buffer.from(response.CP_PhieuGV[0].ExcelData, "base64");
        const blob = new Blob([decoded]);
        myLib.downloadBlob(
          blob,
          exportFormat == "Excel" ? "CP_PhieuGV.xlsx" : "CP_PhieuGV.docx"
        );
      })
      .catch((error) => {
        showError(error);
      });
  }

  doUpdateStatus(HangMucID, TrangThaiGV) {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_PhieuGV_UpdateTrangThai",
        ThamSo: {
          HangMucID: HangMucID,
          TrangThaiGV: TrangThaiGV,
        },
      })
      .then(() => {
        this.doSearch();
      })
      .catch((error) => {
        showError(error);
      });
  }

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              Extra Bill
            </Typography>
          </Stack>
        </AppBar>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.SoQuyetToan}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={this.state.after.SoQuyetToan}
                onChange={(e) => {
                  this.saveField("SoQuyetToan", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.TenCongTy}</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={this.state.after.TenCongTy}
                onChange={(e) => {
                  this.saveField("TenCongTy", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <FormLabel>{fieldLabels.IsDocking}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                id={"IsDocking"}
                key={"IsDocking"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Docking}
                optionLabel="label"
                optionValue="value"
                value={this.state.DanhMuc.DM_Docking.find(
                  (c) => c.value == this.state.after.IsDocking
                )}
                onChange={(item) => {
                  this.saveField("IsDocking", item.value);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.props.close();
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: 400,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="QuyetToanID"

              minRowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" });
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                    open_detail_PO: true,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    this.setState({
                      showEditForm: true,
                      edittingRecord: null,
                    });
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({
                      showEditForm: true,
                      edittingRecord: this.state.rowSelected,
                    })
                  }}
                >
                  {buttonLabels.btnSua}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={
                    !this.state.rowSelected
                  }
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({
                      showInvoiceForm: true,
                      edittingRecord: this.state.rowSelected
                    })
                  }}
                >
                  {buttonLabels.btnInvoice}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa quyết toán: " +
              this.state.rowSelected.SoQuyetToan +
              " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_QuyetToanSC_Delete",
                      ThamSo: {
                        QuyetToanID: this.state.rowSelected.QuyetToanID,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.open_detail_PO ? (
          <PODetailDialog
            open={true}
            HangMucID={this.state.rowSelected.HangMucID}
            TrangThaiGV={this.state.rowSelected.TrangThaiGV}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ open_detail_PO: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showEditForm ? (
          <ExtraBillEditPage
            open={true}
            ShipData={this.props.ShipData}
            edittingRecord={this.state.edittingRecord}
            close={(saved) => {
              if (saved) {
                this.setState(
                  {
                    showEditForm: false,
                  },
                  () => this.doSearch()
                );
              } else {
                this.setState({
                  showEditForm: false,
                });
              }
            }}
          />
        ) : null}
        {this.state.showInvoiceForm ? (
          <ExtraBillInvoiceEditPage
            open={true}
            QuyetToanID={this.state.edittingRecord.QuyetToanID}
            TruocThue={this.state.edittingRecord.TruocThue}
            SauThue={this.state.edittingRecord.SauThue}
            close={_ => {
              this.setState({
                showInvoiceForm: false,
              });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ExtraBillPage);
