/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import {
  filterStyles,
  loadDataError,
  handleServerError,
  loadGridColumns,
  ReactDataGrid_i18n,
  showError,
  formStyles,
} from "../components/common";
import FilterFieldEditDialog from "./FilterFieldEditDialog";
import TableFieldEditDialog from "./TableFieldEditDialog";
import SimpleLogDialog from "../components/SimpleLogDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const columns_filter = [
  {
    name: "FieldName",
    header: "Tên tiêu chí",
    headerAlign: "center",
    defaultWidth: 220,
  },
  {
    name: "DataType",
    header: "Kiểu dữ liệu",
    headerAlign: "center",
    defaultWidth: 150,
  },
  {
    name: "SkipValue",
    header: "Bỏ qua",
    headerAlign: "center",
    defaultWidth: 100,
  },
  {
    name: "Compare",
    header: "So sánh",
    headerAlign: "center",
    defaultWidth: 120,
  },
];
const columns_tables = [
  {
    name: "DataTable",
    header: "Tên dữ liệu",
    headerAlign: "center",
    defaultWidth: 300,
  },
  {
    name: "ProcedureName",
    header: "Procedure",
    headerAlign: "center",
    defaultWidth: 300,
  },
];
const columns_fields = [
  {
    name: "FieldName",
    header: "Tên trường",
    headerAlign: "center",
    defaultWidth: 200,
  },
  {
    name: "DataType",
    header: "Kiểu dữ liệu",
    headerAlign: "center",
    defaultWidth: 200,
  },
  {
    name: "Scale",
    header: "Length",
    headerAlign: "center",
    defaultWidth: 100,
  },
  {
    name: "Precision",
    header: "Precision",
    headerAlign: "center",
    defaultWidth: 100,
  },
];

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["ReportDataName"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

class ReportDataEditDialog extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    var defines = props.edittingRecord
      ? JSON.parse(props.edittingRecord.ReportFilterDefine)
      : {
          FilterFields: [], //danh sach tieu chi loc
          DataSet: [], //danh sach data set
        };

    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {},
      after: {
        ReportDataID: 0,
        ReportDataName: "",
        ReportDataDesc: "",
        ...props.edittingRecord,
        ReportFilterDefine: defines,
      },
      DataTableName: "<Chưa có>",
      NewDataTable: "",
      DataTableFields: [], //danh sach truong` cua table duoc chon
      rowSelected_2:
        defines.DataSet.length > 0 ? defines.DataSet[0] : undefined,
    };
  }

  componentDidMount() {
    //this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  getDataToSave = () => {
    //tra ve du lieu theo cau truc table
    return null;
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.ReportDataID > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_Report_Data_Defines_Update",
            ThamSo: this.state.after,
          })
          .then((response) => {
            var before = {};
            var after = {
              ...this.state.after,
              ReportDataID: response.Report_Data_Defines[0].ReportDataID,
            };
            Object.assign(before, after);
            this.setState({
              before: before,
              after: after,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, after);
            }
            alert("Cập nhật thành công!");
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  //create table lien quan
  doCreateTables = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.ReportDataID > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_Report_Data_Defines_CreateTable",
            ThamSo: this.state.after,
          })
          .then((response) => {
            var before = {};
            var after = {
              ...this.state.after,
              ReportDataID: response.ReportDataID,
            };
            Object.assign(before, after);
            this.setState({
              before: before,
              after: after,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  //cap nhat tieu chi tim kiem
  updateFilterField(field) {
    var find = this.state.after.ReportFilterDefine.FilterFields.find(
      (item) => item.FieldName == field.FieldName
    );
    if (find) {
      myLib.assign(field, find, [
        "FieldName",
        "DataType",
        "SkipValue",
        "Compare",
      ]);
    } else {
      this.state.after.ReportFilterDefine.FilterFields.push(field);
    }
    //hien thi lai
    this.setState({ saving: false });
  }
  updateTableField(field) {
    var table = this.state.rowSelected_2;
    if (table) {
      var find = table.Fields.find((item) => item.FieldName == field.FieldName);
      if (find) {
        myLib.assign(field, find, [
          "FieldName",
          "DataType",
          "Scale",
          "Precision",
        ]);
      } else {
        table.Fields.push(field);
      }
      //hien thi lai
      this.setState({ saving: false });
    }
  }
  doGenerate_Procedure_Thamso() {
    //tao script procedure tham so
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_Report_Data_Defines_GetProcedureThamSo",
        ThamSo: {
          ReportDataID: this.state.after.ReportDataID,
          ReportDataName: this.state.after.ReportDataName,
        },
      })
      .then((response) => {
        //xu ly response
        console.log(response);
        var script = response.ProcedureScript[0].Script;
        this.setState({ Script: script, open_show_script: true });
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  doGenerate_Procedure_DuLieu() {
    //tao script procedure du lieu
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_Report_Data_Defines_GetProcedureData",
        ThamSo: {
          ReportDataID: this.state.after.ReportDataID,
          ReportDataName: this.state.after.ReportDataName,
          DataTable: this.state.rowSelected_2.DataTable,
        },
      })
      .then((response) => {
        //xu ly response
        console.log(response);
        var script = response.ProcedureScript[0].Script;
        this.setState({ Script: script, open_show_script: true });
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Kho dữ liệu</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Mã số:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  disabled
                  value={this.state.after.ReportDataID}
                />
              </Grid>
              <Grid item xs={16}></Grid>
              <Grid item xs={4}>
                <FormLabel>Tên dữ liệu (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  autoFocus
                  value={this.state.after.ReportDataName}
                  onChange={(event) => {
                    this.saveField("ReportDataName", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportDataName !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Mô tả:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.ReportDataDesc}
                  onChange={(event) => {
                    this.saveField("ReportDataDesc", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportDataDesc !== undefined}
                />
              </Grid>
              <Grid item xs={24}>
                <FormLabel>Danh sách tiêu chí lọc: </FormLabel>
              </Grid>
              <Grid item xs={20}>
                <ReactDataGrid
                  style={{ height: 200, fontSize: 12 }}
                  showZebraRows={true}
                  i18n={ReactDataGrid_i18n}
                  columns={columns_filter}
                  pagination={false}
                  multiSelect={false}
                  showHoverRows={false}
                  checkboxColumn={{
                    render: (props) => (
                      <input
                        type="checkbox"
                        readOnly
                        checked={props.rowSelected}
                      ></input>
                    ),
                  }}
                  dataSource={this.state.after.ReportFilterDefine.FilterFields}
                  idProperty="FieldName"
                  headerHeight={30}
                  rowHeight={30}
                  allowUnsort={true}
                  skipLoadOnMount={true}
                  enableSelection={true}
                  onSelectionChange={({ data, selected, unselected }) => {
                    var now = new Date().getTime();
                    var diff = now - this.lastClick;
                    if (diff > 300) {
                      this.setState({ rowSelected_1: data });
                    } else {
                      //double click
                      this.setState({
                        rowSelected_1: data,
                      });
                    }
                    this.lastClick = now;
                  }}
                ></ReactDataGrid>
              </Grid>
              <Grid item xs={4}>
                <Button
                  disabled={this.state.saving}
                  onClick={() => {
                    this.setState({ open_edit_filter_field: true });
                  }}
                  variant="contained"
                >
                  Thêm
                </Button>
                <Button
                  disabled={
                    this.state.saving || this.state.rowSelected_1 == undefined
                  }
                  onClick={() => {
                    this.setState({
                      open_edit_filter_field: true,
                      edittingRecord: this.state.rowSelected_1,
                    });
                  }}
                  variant="contained"
                >
                  Sửa
                </Button>
                <Button
                  disabled={
                    this.state.saving || this.state.rowSelected_1 == undefined
                  }
                  onClick={() => {
                    var field = this.state.rowSelected_1;
                    var index =
                      this.state.after.ReportFilterDefine.FilterFields.findIndex(
                        (item) => item.FieldName == field.FieldName
                      );
                    if (index >= 0) {
                      this.state.after.ReportFilterDefine.FilterFields.splice(
                        index,
                        1
                      );
                      this.setState({
                        saving: false,
                        rowSelected_1: undefined,
                      });
                    }
                  }}
                  variant="contained"
                >
                  Xóa
                </Button>
                <Button
                  disabled={this.state.saving}
                  onClick={() => {
                    this.doGenerate_Procedure_Thamso();
                  }}
                  variant="contained"
                >
                  Template code
                </Button>
              </Grid>

              <Grid item xs={12}>
                <FormLabel>Danh sách dữ liệu: </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>
                  Danh sách trường dữ liệu:{" "}
                  {this.state.rowSelected_2
                    ? this.state.rowSelected_2.DataTable
                    : "(chưa có)"}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <ReactDataGrid
                  style={{ height: 200, fontSize: 12 }}
                  showZebraRows={true}
                  i18n={ReactDataGrid_i18n}
                  columns={columns_tables}
                  pagination={false}
                  multiSelect={false}
                  showHoverRows={false}
                  checkboxColumn={{
                    render: (props) => (
                      <input
                        type="checkbox"
                        readOnly
                        checked={props.rowSelected}
                      ></input>
                    ),
                  }}
                  dataSource={this.state.after.ReportFilterDefine.DataSet}
                  idProperty="DataTable"
                  headerHeight={30}
                  rowHeight={30}
                  allowUnsort={true}
                  skipLoadOnMount={true}
                  enableSelection={true}
                  onSelectionChange={({ data, selected, unselected }) => {
                    var now = new Date().getTime();
                    var diff = now - this.lastClick;
                    if (diff > 300) {
                      this.setState({ rowSelected_2: data });
                    } else {
                      //double click
                      this.setState({
                        rowSelected_2: data,
                      });
                    }
                    this.lastClick = now;
                  }}
                ></ReactDataGrid>
              </Grid>
              <Grid item xs={12}>
                <ReactDataGrid
                  style={{ height: 200, fontSize: 12 }}
                  showZebraRows={true}
                  i18n={ReactDataGrid_i18n}
                  columns={columns_fields}
                  pagination={false}
                  multiSelect={false}
                  showHoverRows={false}
                  checkboxColumn={{
                    render: (props) => (
                      <input
                        type="checkbox"
                        readOnly
                        checked={props.rowSelected}
                      ></input>
                    ),
                  }}
                  dataSource={
                    this.state.rowSelected_2
                      ? this.state.rowSelected_2.Fields
                      : []
                  }
                  idProperty="FieldName"
                  headerHeight={30}
                  rowHeight={30}
                  allowUnsort={true}
                  skipLoadOnMount={true}
                  enableSelection={true}
                  onSelectionChange={({ data, selected, unselected }) => {
                    var now = new Date().getTime();
                    var diff = now - this.lastClick;
                    if (diff > 300) {
                      this.setState({ rowSelected_3: data });
                    } else {
                      //double click
                      this.setState({
                        rowSelected_3: data,
                      });
                    }
                    this.lastClick = now;
                  }}
                ></ReactDataGrid>
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  placeholder="Tên table"
                  style={{ textTransform: "uppercase" }}
                  value={this.state.NewDataTable}
                  onChange={(event) => {
                    this.setState({
                      NewDataTable: event.target.value.toUpperCase(),
                    });
                  }}
                  error={this.state.validateStatus.NewDataTable !== undefined}
                />
                <Button
                  disabled={this.state.saving}
                  onClick={() => {
                    if (this.state.NewDataTable !== "") {
                      var newTable = {
                        DataTable:
                          "Report_DataTable_" +
                          this.state.after.ReportDataName +
                          "_" +
                          this.state.NewDataTable,
                        Fields: [],
                        ProcedureName:
                          "Proc_Report_Export_" +
                          this.state.after.ReportDataName +
                          "_" +
                          this.state.NewDataTable,
                      };
                      this.state.after.ReportFilterDefine.DataSet.push(
                        newTable
                      );
                      this.setState({
                        saving: false,
                        NewDataTable: "",
                        rowSelected_2: newTable,
                      });
                    }
                  }}
                  variant="contained"
                >
                  Thêm
                </Button>
                <Button
                  disabled={
                    this.state.saving ||
                    this.state.rowSelected_2 == undefined ||
                    this.state.NewDataTable == ""
                  }
                  onClick={() => {
                    if (
                      this.state.rowSelected_2 &&
                      this.state.NewDataTable !== ""
                    ) {
                      var row = this.state.rowSelected_2;
                      row.DataTable =
                        "Report_DataTable_" +
                        this.state.after.ReportDataName +
                        "_" +
                        this.state.NewDataTable;
                      row.ProcedureName =
                        "Proc_Report_Export_" +
                        this.state.after.ReportDataName +
                        "_" +
                        this.state.NewDataTable;
                      this.setState({
                        saving: false,
                        NewDataTable: "",
                        rowSelected_2: undefined,
                      });
                    }
                  }}
                  variant="contained"
                >
                  Sửa
                </Button>
                <Button
                  disabled={
                    this.state.saving || this.state.rowSelected_2 == undefined
                  }
                  onClick={() => {
                    var field = this.state.rowSelected_2;
                    var index =
                      this.state.after.ReportFilterDefine.DataSet.findIndex(
                        (item) => item.DataTable == field.DataTable
                      );
                    if (index >= 0) {
                      this.state.after.ReportFilterDefine.DataSet.splice(
                        index,
                        1
                      );
                      this.setState({
                        saving: false,
                        rowSelected_2: undefined,
                      });
                    }
                  }}
                  variant="contained"
                >
                  Xóa
                </Button>
                <Button
                  disabled={
                    this.state.saving || this.state.rowSelected_2 == undefined
                  }
                  onClick={() => {
                    this.doGenerate_Procedure_DuLieu();
                  }}
                  variant="contained"
                >
                  Template code
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={
                    this.state.saving || this.state.rowSelected_2 == undefined
                  }
                  onClick={() => {
                    this.setState({ open_edit_table_field: true });
                  }}
                  variant="contained"
                >
                  Thêm
                </Button>
                <Button
                  disabled={
                    this.state.saving ||
                    this.state.rowSelected_2 == undefined ||
                    this.state.rowSelected_3 == undefined
                  }
                  onClick={() => {
                    this.setState({
                      open_edit_table_field: true,
                      edittingRecord: this.state.rowSelected_3,
                    });
                  }}
                  variant="contained"
                >
                  Sửa
                </Button>
                <Button
                  disabled={
                    this.state.saving || this.state.rowSelected_3 == undefined
                  }
                  onClick={() => {
                    var table = this.state.rowSelected_2;
                    if (table) {
                      var field = this.state.rowSelected_3;
                      var index = table.Fields.findIndex(
                        (item) => item.FieldName == field.FieldName
                      );
                      if (index >= 0) {
                        table.Fields.splice(index, 1);
                        this.setState({
                          saving: false,
                          rowSelected_3: undefined,
                        });
                      }
                    }
                  }}
                  variant="contained"
                >
                  Xóa
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Lưu
          </Button>
          <Button
            disabled={this.state.saving || this.state.after.ReportDataID == 0}
            onClick={() => {
              this.doCreateTables();
            }}
            variant="contained"
          >
            Create tables
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_edit_filter_field ? (
          <FilterFieldEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={(record) => {
              this.setState({
                open_edit_filter_field: false,
                edittingRecord: undefined,
              });
              if (record) {
                this.updateFilterField(record);
              }
            }}
          />
        ) : null}
        {this.state.open_edit_table_field ? (
          <TableFieldEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={(record) => {
              this.setState({
                open_edit_table_field: false,
                edittingRecord: undefined,
              });
              if (record) {
                this.updateTableField(record);
              }
            }}
          />
        ) : null}
        {this.state.open_show_script ? (
          <SimpleLogDialog
            open={true}
            Text={this.state.Script}
            close={(record) => {
              this.setState({
                open_show_script: false,
              });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ReportDataEditDialog
);
