import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  FormLabel,
  Grid,
  IconButton,
  Link,
  OutlinedInput,
  Stack
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import MyDateEditor from "../../components/MyDateEditor";
import MyNumberEditor from "../../components/MyNumberEditor";
import SimpleDialog from "../../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnDelete: "Delete",
  }
  : {
    btnSave: "Lưu",
    btnDelete: "Xóa"
  };
const fieldLabels_EN = {
  HoaDon_So: "Invoice No",
  HoaDon_Ngay: "Invoice date",
  HoaDon_TruocThue: "Value before tax",
  HoaDon_Tyle: "Tax rate (%)",
  HoaDon_SauThue: "Value after tax",
  HoaDon_HanTT: "Payment due date"
};
const fieldLabels_VN = {
  HoaDon_So: "Số hóa đơn",
  HoaDon_Ngay: "Ngày xuất hóa đơn",
  HoaDon_TruocThue: "Giá trị hóa đơn trước thuế",
  HoaDon_Tyle: "Thuế (%)",
  HoaDon_SauThue: "Giá trị hóa đơn sau thuế",
  HoaDon_HanTT: "Thời hạn trả tiền"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class InvoiceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_CongTy: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        QuyetToanID: this.props.QuyetToanID,
        DanhSachHoaDon: [this.newRecord()]
      }
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_QuyetToanSC_GetHoaDon",
        ThamSo: {
          QuyetToanID: this.props.QuyetToanID,
        }
      })
      .then((response) => {
        this.saveField("DanhSachHoaDon", Array.isArray(response.CP_Invoice) && response.CP_Invoice.length > 0 ? response.CP_Invoice :
          [{
            HoaDon_So: null,
            HoaDon_Ngay: null,
            HoaDon_TruocThue: null,
            HoaDon_Tyle: null,
            HoaDon_SauThue: null
          }]);
      })
      .catch((error) => {
        showError(error);
      });
  }

  saveField = (fieldName, value, callback) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false }, () => {
      if (callback) {
        callback();
      }
    });
  };

  saveRecord = (record, index, field, value, callback) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.DanhSachHoaDon[index] = record;
    s.dataChanged = true;
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };

  newRecord() {
    return {
      HoaDon_So: null,
      HoaDon_Ngay: null,
      HoaDon_TruocThue: null,
      HoaDon_Tyle: null,
      HoaDon_SauThue: null
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.DanhSachHoaDon.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.DanhSachHoaDon.splice(index, 1);
    s.after.DanhSachHoaDon.forEach((job, index) => {
      return {
        ...job,
        STT: index + 1,
      };
    });
    s.dataChanged = true;

    this.setState(s);
  }

  validate_Data(data) {
    //validate danh sach truong khong duoc trong'
    var ret = myLib.validateFields(data, [
      "QuyetToanID"
    ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

    ret.error = Object.keys(ret).length > 0;

    ret.DanhSachHoaDon = data.DanhSachHoaDon.map((hoadon) => {
      return {
        HoaDon_So: myLib.isEmpty(hoadon.HoaDon_So),
        HoaDon_Ngay: myLib.isEmpty(hoadon.HoaDon_Ngay),
        HoaDon_TruocThue: myLib.isEmpty(hoadon.HoaDon_TruocThue),
        HoaDon_Tyle: myLib.isEmpty(hoadon.HoaDon_Tyle),
        HoaDon_SauThue: myLib.isEmpty(hoadon.HoaDon_SauThue),
        HoaDon_HanTT: myLib.isEmpty(hoadon.HoaDon_HanTT)
      };
    });

    ret.error = ret.error || ret.DanhSachHoaDon.some((hoadon) => hoadon.HoaDon_So || hoadon.HoaDon_Ngay || hoadon.HoaDon_TruocThue || hoadon.HoaDon_Tyle || hoadon.HoaDon_SauThue || hoadon.HoaDon_HanTT);

    return ret;
  }

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });

        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_CP_QuyetToanSC_HoaDon",
            ThamSo: {
              QuyetToanID: this.state.after.QuyetToanID,
              DanhSachHoaDon: this.state.after.DanhSachHoaDon.map(hoadon => {
                return {
                  HoaDon_So: hoadon.HoaDon_So,
                  HoaDon_Ngay: hoadon.HoaDon_Ngay,
                  HoaDon_TruocThue: hoadon.HoaDon_TruocThue,
                  HoaDon_Tyle: hoadon.HoaDon_Tyle,
                  HoaDon_SauThue: hoadon.HoaDon_SauThue,
                  HoaDon_HanTT: hoadon.HoaDon_HanTT
                }
              })
            }
          })
          .then(() => {
            this.loadData();
            toast.success("Saved data successfully.")
          })
          .catch((error) => {
            handleServerError(error);
          })
          .finally(() => {
            this.setState({ saving: false });
          });;
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  isBeforeTaxValueValid = () => this.state.after.DanhSachHoaDon.reduce((total, hoadon) => total + hoadon.HoaDon_TruocThue, 0) == this.props.TruocThue;
  isAfterTaxValueValid = () => this.state.after.DanhSachHoaDon.reduce((total, hoadon) => total + hoadon.HoaDon_SauThue, 0) == this.props.SauThue;

  render() {
    return <>
      <Stack spacing={2}>
        <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
          <Grid item xs={1}>
            <FormLabel>
              <b>TT</b>
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <b>{fieldLabels.HoaDon_So}</b>
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <b>{fieldLabels.HoaDon_Ngay}</b>
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <b>{fieldLabels.HoaDon_TruocThue}</b>
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <b>{fieldLabels.HoaDon_Tyle}</b>
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <b>{fieldLabels.HoaDon_SauThue}</b>
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <b>{fieldLabels.HoaDon_HanTT}</b>
            </FormLabel>
          </Grid>
        </Grid>
        {this.state.after.DanhSachHoaDon.map((item, index) => (
          <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
            <Grid item xs={1}>
              <FormLabel>{index + 1}.</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <OutlinedInput
                fullWidth
                value={item.HoaDon_So ?? ""}
                onChange={(event) => {
                  this.saveRecord(
                    item,
                    index,
                    "HoaDon_So",
                    event.target.value
                  );
                }}
                error={
                  Array.isArray(this.state.validateStatus.DanhSachHoaDon) &&
                  this.state.validateStatus.DanhSachHoaDon[index]?.HoaDon_So == true
                }
              />
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                fullWidth
                value={item.HoaDon_Ngay}
                onChange={(value) => {
                  this.saveRecord(
                    item,
                    index,
                    "HoaDon_Ngay",
                    value
                  );
                }}
                error={
                  Array.isArray(this.state.validateStatus.DanhSachHoaDon) &&
                  this.state.validateStatus.DanhSachHoaDon[index]?.HoaDon_Ngay == true
                }
              />
            </Grid>
            <Grid item xs={3}>
              <MyNumberEditor
                fullWidth
                value={item.HoaDon_TruocThue}
                decimalScale={0}
                onValueChange={(value) => {
                  this.saveRecord(
                    item,
                    index,
                    "HoaDon_TruocThue",
                    value,
                    () => {
                      this.saveRecord(
                        item,
                        index,
                        "HoaDon_SauThue",
                        Math.round(value / 100.0 * (100 + +item.HoaDon_Tyle))
                      );
                    }
                  );
                }}
                error={
                  (Array.isArray(this.state.validateStatus.DanhSachHoaDon) &&
                    this.state.validateStatus.DanhSachHoaDon[index]?.HoaDon_TruocThue == true) ||
                  !this.isBeforeTaxValueValid()
                }
              />
            </Grid>
            <Grid item xs={3}>
              <MyNumberEditor
                fullWidth
                value={item.HoaDon_Tyle}
                onValueChange={(value) => {
                  this.saveRecord(
                    item,
                    index,
                    "HoaDon_Tyle",
                    value,
                    () => {
                      this.saveRecord(
                        item,
                        index,
                        "HoaDon_SauThue",
                        Math.round(item.HoaDon_TruocThue / 100.0 * (100 + value))
                      );
                    }
                  );
                }}
                error={
                  Array.isArray(this.state.validateStatus.DanhSachHoaDon) &&
                  this.state.validateStatus.DanhSachHoaDon[index]?.HoaDon_Tyle == true
                }
              />
            </Grid>
            <Grid item xs={3}>
              <MyNumberEditor
                fullWidth
                value={item.HoaDon_SauThue}
                decimalScale={0}
                onValueChange={(value) => {
                  this.saveRecord(
                    item,
                    index,
                    "HoaDon_SauThue",
                    value
                  );
                }}
                error={
                  (Array.isArray(this.state.validateStatus.DanhSachHoaDon) &&
                    this.state.validateStatus.DanhSachHoaDon[index]?.HoaDon_SauThue == true) ||
                  !this.isAfterTaxValueValid()
                }
              />
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                fullWidth
                value={item.HoaDon_HanTT}
                onChange={(value) => {
                  this.saveRecord(
                    item,
                    index,
                    "HoaDon_HanTT",
                    value
                  );
                }}
                error={
                  (Array.isArray(this.state.validateStatus.DanhSachHoaDon) &&
                    this.state.validateStatus.DanhSachHoaDon[index]?.HoaDon_HanTT == true) ||
                  item.HoaDon_HanTT < new Date()
                }
              />
            </Grid>
            {index == 0 ? (
              this.state.after.DanhSachHoaDon.length > 1 ? (
                <>
                  <Grid item xs={2}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        this.addRecord();
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        this.addRecord("outlined-input");
                      }}
                    >
                      <AddBoxIcon color="primary" size={24} />
                    </IconButton>
                    <IconButton
                      size="small"
                      fullWidth
                      onClick={() => {
                        this.removeRecord(index);
                      }}
                    >
                      <ClearIcon color="warning" size={24} />
                    </IconButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.addRecord();
                    }}
                    onContextMenu={() => {
                      this.addRecord("outlined-input");
                    }}
                  >
                    <AddBoxIcon color="primary" size={24} />
                  </IconButton>
                </Grid>
              )
            ) : (
              <>
                <Grid item xs={2}>
                  <IconButton
                    size="small"
                    fullWidth
                    onClick={() => {
                      this.removeRecord(index);
                    }}
                  >
                    <ClearIcon color="warning" size={24} />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
        ))}
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "10px" }}>
          <Button
            color="success"
            variant="contained"
            disabled={!this.state.dataChanged || this.state.saving}
            onClick={() => this.doSave()}
          >
            {buttonLabels.btnSave}
          </Button>
          {this.state.after.DanhSachHoaDon[0].HoaDon_So != null && <Link
            href="#"
            sx={{ color: "red", zIndex: 0 }}
            onClick={() => {
              this.setState({ showConfirmXoa: true });
            }}
          >
            {buttonLabels.btnDelete}
          </Link>}
        </Stack >
      </Stack>

      {this.state.showConfirmXoa ? (
        <SimpleDialog
          confirm
          message={
            "Bạn muốn xóa hóa đơn?"
          }
          open={true}
          OKRunning={this.state.saving}
          close={(ok) => {
            if (ok) {
              //thuc hien xoa
              this.setState({ saving: true });
              server
                .post("ChiPhi/DoRequest", {
                  Function: "Proc_CP_QuyetToanSC_HoaDon",
                  ThamSo: {
                    QuyetToanID: this.props.QuyetToanID,
                    DanhSachHoaDon: null
                  },
                })
                .then(() => {
                  this.setState({ showConfirmXoa: false, saving: false });
                  //reload
                  this.loadData();
                })
                .catch((error) => {
                  this.setState({ saving: false });
                  handleServerError(error);
                });
            } else {
              this.setState({ showConfirmXoa: false });
            }
          }}
        />
      ) : null}
    </>
  }
}


export default withStyles(formStyles, { withTheme: true })(InvoiceTab);