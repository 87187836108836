import React from "react";
import SelectFilter from "react-select";
import {
  filterStyles,
  
} from "./common";

export default class MySelectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      inputValue: "",
      options: props.options,
    };
  }
  
  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log(nextProps.options);
    return {
      options: nextProps.options
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  //when dropdown is not manually clicked then it does not scroll into view
  onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("MySelectFilter__option--is-selected")[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
      }
    }, 15);
  };

  render() {
    return (
      <SelectFilter
        className={"MySelectFilter"}
        classNamePrefix={"MySelectFilter"}
        fullWidth={this.props.fullWidth}
        autoFocus={this.props.autoFocus}
        options={this.state.options}
        getOptionLabel={(item) => item[this.props["optionLabel"]]}
        getOptionValue={(item) => item[this.props["optionValue"]]}
        placeholder={this.props.placeholder}
        styles={filterStyles}
        menuPosition={"fixed"}
        value={this.props.value}
        isMulti={this.props.isMulti}
        isClearable
        onMenuOpen={this.onMenuOpen}
        isDisabled={this.props.readOnly}
        onChange={(item) => {
          var temp = {};
          if (this.props.isMulti) {
            temp = item;
          } else {
            temp[this.props.optionLabel] = item
              ? item[this.props["optionLabel"]]
              : null;
            temp[this.props.optionValue] = item
              ? item[this.props["optionValue"]]
              : null;
          }
          if (this.props.onChange) {
            this.props.onChange(temp, item);
          }
        }}
        error={this.props.error}
      ></SelectFilter>
    );
  }
}
