import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  OutlinedInput,
  Grid,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/theme/blue-dark.css";
import "@inovua/reactdatagrid-community/theme/amber-light.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportDataEditDialog from "../danhmuc/ReportDataEditDialog";
import SimpleDialog from "../components/SimpleDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import GenerateCodeReactDialog from "../tools/GenerateCodeReactDialog";
import MySelectFilter from "../components/MySelectFilter";

import {
  filterStyles,
  loadDataError,
  handleServerError,
  loadGridColumns,
  ReactDataGrid_i18n,
} from "../components/common";
import moment from "moment";
import SelectFilter from "react-select";
import CheckRuleEditDialog from "./CheckRuleEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const fieldLabels = englishUI
  ? {
      FormTitle: "LIST OF CHECKING RULE",
      ShipID: "Ship' name:",
    }
  : {
      FormTitle: "DANH MỤC QUY TẮC KIỂM SOÁT",
      ShipID: "Tên tàu:",
    };
const buttonLabels = englishUI
  ? {
      btnTimKiem: "Search",
      btnThem: "Add",
      btnSua: "Edit",
      btnXoa: "Delete",
      btnChon: "Select",
      btnThoat: "Close",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnThem: "Thêm",
      btnSua: "Sửa",
      btnXoa: "Xóa",
      btnChon: "Chọn",
      btnThoat: "Thoát",
    };
const default_gridColumns = englishUI
  ? [
      {
        name: "ShipID",
        type: "number",
        header: "ID Ship",
        headerAlign: "center",
        defaultWidth: 90,
      },

      {
        name: "ShipName",
        type: "string",
        header: "Ship' name",
        headerAlign: "center",
        defaultWidth: 180,
      },
      {
        name: "CheckRuleID",
        type: "string",
        header: "Rule ID",
        headerAlign: "center",
        defaultWidth: 155,
      },
      {
        name: "RuleName",
        header: "Rule' name",
        headerAlign: "center",
        defaultWidth: 250,
      },
      {
        name: "WarningLevel",
        header: "Level",
        headerAlign: "center",
        defaultWidth: 90,
      },
      {
        name: "MinValue",
        header: "Value 1",
        headerAlign: "center",
        defaultWidth: 90,
      },
      {
        name: "MaxValue",
        header: "Value 2",
        headerAlign: "center",
        defaultWidth: 90,
      },
       
      {
        name: "Active",
        header: "Active",
        headerAlign: "center",
        defaultWidth: 90,
        render: ({data})=>data.Active ? "X" : ""
      },
      {
        name: "NguoiCN",
        header: "Updated by",
        headerAlign: "center",
        defaultWidth: 150,
      },
      {
        name: "NgayCN",
        header: "Updated time",
        headerAlign: "center",
        defaultWidth: 140,
        render: (props) => moment(props.data.NgayCN).format("DD/MM/YYYY HH:mm"),
      },
    ]
  : [
      {
        name: "ShipID",
        type: "number",
        header: "Mã tàu",
        headerAlign: "center",
        defaultWidth: 85,
      },
      {
        name: "ShipName",
        type: "string",
        header: "Tên tàu",
        headerAlign: "center",
        defaultWidth: 225,
      },
      {
        name: "CheckRuleID",
        type: "string",
        header: "Mã quy tắc",
        headerAlign: "center",
        defaultWidth: 155,
      },
      {
        name: "RuleName",
        header: "Tên quy tắc",
        headerAlign: "center",
        defaultWidth: 250,
      },
      {
        name: "Active",
        header: "Hoạt động",
        headerAlign: "center",
        defaultWidth: 90,
        render: ({data})=>data.Active ? "X" : ""
      },
      {
        name: "NguoiCN",
        header: "Người CN",
        headerAlign: "center",
        defaultWidth: 120,
      },
      {
        name: "NgayCN",
        header: "Ngày CN",
        headerAlign: "center",
        defaultWidth: 120,
        render: (props) => moment(props.data.NgayCN).format("DD/MM/YYYY HH:mm"),
      },
    ];

//neu co dinh nghia trong global thi lay ra, khong thi lay default

const defaultSortInfo = {
  id: "CheckRuleID",
  name: "CheckRuleID",
  dir: 1,
  type: "string",
};
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};
////////////////////////////////////////////////////////////////////////////
export default class CheckRuleChonDialog extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click

  constructor(props) {
    super(props);

    this.state = {
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      saving: false, //co dang save du lieu hay khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      loading_coquan: true, // co dang load ding danh muc nguon tin ko
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong

      DanhMuc: {
        DM_Ship: [],
      },
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo,
      after: {
        PageIndex: 1,
        PageSize: 100,
        Ascending: true,
        SortBy: getSortColumnIndex(default_gridColumns, defaultSortInfo), //index column duoc sap xep
        //tieu chi tim kiem
        ShipID: "",
        ShipName: "",
      },
      rowSelected: undefined, //dang lua chon row nao
      showEditDialog: false, // hien thi form cap nhat hay khong
      edittingRecord: undefined, //ban ghi chon de edit
      showConfirmXoa: false, //hien thi dialog xoa
      hasQuyen: global.hasRoles("R141"),
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  handleClose(selection) {
    this.props.close(selection);
  }

  saveField = (fieldName, value) => {
    var s = this.state;
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    }
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setState(this.state);
    } else {
      //neu lien quan den phan trang, sap xep thi load lai du lieu tu server
      this.setState(this.state, () => {
        this.doSearch();
      });
    }
    this.setState(this.state);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s, ()=>this.doSearch());
  };
  //load danh muc tau
  loadDanhMuc() {
    this.setState({ saving: true });
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: "DM_Ship",
        },
      })
      .then((response) => {
        //du lieu cua 1 do thi
        var default_ship = {};
        if (response.DM_Ship.length > 0) {
          default_ship = myLib.copy(response.DM_Ship[0], [
            "ShipID",
            "ShipName",
          ]);
        }
        this.setState(
          {
            DanhMuc: response,
            after: {
              ...this.state.after,
              ...default_ship,
            },
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({ saving: false });
      });
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve, eject) => {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_DM_CheckRule_Search",
          ThamSo: {
            ...this.state.after,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(default_gridColumns, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectedRecord: {},
    });
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AppBar sx={{ position: "relative" }}>
            <Stack direction="row">
              <Typography
                sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
                variant="h6"
                component="div"
              >
                {fieldLabels.FormTitle}
              </Typography>
              <Stack direction="row" spacing={4}>
                {this.state.loading_data ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.saving ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.hasQuyen ? (
                  <>
                    
                    <Button
                      color="success"
                      variant="contained"
                      disabled={this.state.rowSelected === undefined}
                      onClick={() => {
                        this.setState({
                          showEditDialog: true,
                          edittingRecord: this.state.rowSelected,
                        });
                      }}
                    >
                      {buttonLabels.btnSua}
                    </Button>
                    <Button
                      color="success"
                      variant="contained"
                      disabled={this.state.rowSelected === undefined}
                      onClick={() => {
                        this.setState({ showConfirmXoa: true });
                      }}
                    >
                      {buttonLabels.btnXoa}
                    </Button>
                  </>
                ) : null}
                {this.props.disableChon ? null : (
                  <Button
                    color="success"
                    variant="contained"
                    disabled={this.state.rowSelected === undefined}
                    onClick={() => {
                      this.props.close(
                        this.state.rowSelected,
                        this.state.search_result.data
                      );
                    }}
                  >
                    {buttonLabels.btnChon}
                  </Button>
                )}
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.handleClose(undefined)}
                >
                  {buttonLabels.btnThoat}
                </Button>
              </Stack>
            </Stack>
          </AppBar>
          <Paper variant="outlined">
            <Grid container spacing={1} columns={12}>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.ShipID}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <MySelectFilter
                  id={"ShipID"}
                  key={"ShipID"}
                  fullWidth
                  autoFocus={false}
                  options={this.state.DanhMuc.DM_Ship}
                  optionLabel="ShipName"
                  optionValue="ShipID"
                  placeholder=""
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item);
                  }}
                ></MySelectFilter>
              </Grid>
              <Grid item xs={2}>
                <ButtonTimKiem
                  variant="contained"
                  disabled={this.state.search_loading}
                  startIcon={<SearchIcon fontSize="small" />}
                  title={buttonLabels.btnTimKiem}
                  onClick={this.doSearch}
                  onRightClickMenu={(item) => {
                    if (item.menuName == "CodeSearch") {
                      this.setState({ open_code_seach: true });
                    }
                    if (item.menuName == "Columns") {
                      this.setState({ open_columns_define: true });
                    }
                    if (item.menuName == "Generate") {
                      this.setState({ open_generate_code: true });
                    }
                    if (item.menuName == "GenerateJs") {
                      this.setState({ open_generate_react: true });
                    }
                  }}
                ></ButtonTimKiem>
              </Grid>
            </Grid>
          </Paper>
          <ReactDataGrid
            style={{ height: 450, fontSize: 12 }}
            showZebraRows={true}
            i18n={englishUI ? undefined : ReactDataGrid_i18n}
            columns={default_gridColumns}
            pagination={true}
            multiSelect={false}
            showHoverRows={false}
            checkboxColumn={{
              render: (props) => (
                <input
                  type="checkbox"
                  readOnly
                  checked={props.rowSelected}
                ></input>
              ),
            }}
            defaultLimit={10}
            limit={this.state.after.PageSize}
            skip={(this.state.after.PageIndex - 1) * this.state.after.PageSize}
            onSkipChange={(skip) => {
              this.saveField("PageIndex", skip / this.state.after.PageSize + 1);
            }}
            dataSource={this.state.search_dataSource}
            idProperty="CheckRuleID"
            headerHeight={30}
            rowHeight={30}
            allowUnsort={false}
            defaultSortInfo={defaultSortInfo}
            sortInfo={this.state.sortInfo}
            onSortInfoChange={this.onSortInfoChange}
            onLimitChange={(v) => this.saveField("PageSize", v)}
            emptyText={loadDataError(
              this.state.search_error,
              this.state.search_result.data.length === 0,
              this.state.errorMessage,
              this.state.errorLog
            )}
            skipLoadOnMount={true}
            enableSelection={true}
            onSelectionChange={({ data, selected, unselected }) => {
              var now = new Date().getTime();
              var diff = now - this.lastClick;
              if (diff > 300) {
                this.setState({ rowSelected: data });
              } else {
                //double click
                this.setState(
                  {
                    rowSelected: data,
                  },
                  () => {
                    if (!this.props.disableChon) {
                      this.props.close(
                        this.state.rowSelected,
                        this.state.search_result.data
                      );
                    }
                  }
                );
              }
              this.lastClick = now;
            }}
          ></ReactDataGrid>
        </DialogContent>
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={default_gridColumns}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}
        {this.state.open_columns_define ? (
          <SearchFormColumnsDialog
            open={true}
            formName={this.constructor.name}
            columns_default={default_gridColumns}
            close={(record) => {
              if (record) {
                this.columns_grid = myLib.applyGridColumns(
                  default_gridColumns,
                  record
                );
              }
              this.setState({
                open_columns_define: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_react ? (
          <GenerateCodeReactDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_react: false,
              });
            }}
          />
        ) : null}
        {this.state.showEditDialog ? (
          <CheckRuleEditDialog
            open={true}
            ShipData={this.state.after}
            edittingRecord={this.state.edittingRecord}
            close={(record) => {
              this.setState(
                {
                  showEditDialog: false,
                  edittingRecord: undefined,
                  rowSelected: undefined,
                },
                () => this.doSearch()
              );
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa:" + this.state.rowSelected.ShipID + " ?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_DM_CheckRule_Delete",
                      ThamSo: this.state.rowSelected,
                    })
                    .then((response) => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
