import {
  Article,
  TableView
} from '@mui/icons-material';
import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LockIcon from '@mui/icons-material/Lock';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { withStyles } from "@mui/styles";
import NumericFormat from "react-number-format";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import ExtraBillPage from './ExtraBillPage';
import PODetailDialog from "./PODetail/PODetailDialog";
import POEditDialog from "./POEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    Nam: "Year:",
    IsKetThuc: "Status:",
    IsDocking: "Docking:"
  }
  : {
    Nam: "Năm:",
    IsKetThuc: "Trạng thái:",
    Docking: "Docking:"
  };
const buttonExports = [
  {
    ID: "Word",
    Form: "TiengAnh",
    Label: "Word - English",
    LabelEN: "Word - English",
    Icon: <Article fontSize="small" />,
  },
  {
    ID: "Word",
    Form: "TiengViet",
    Label: "Word - Tiếng Việt",
    LabelEN: "Word - Tiếng Việt",
    Icon: <Article fontSize="small" />,
  },
  {
    ID: "Excel",
    Form: "TiengAnh",
    Label: "Excel - English",
    LabelEN: "Excel - English",
    Icon: <TableView fontSize="small" />,
  },
  {
    ID: "Excel",
    Form: "TiengViet",
    Label: "Excel - Tiếng Việt",
    LabelEN: "Excel - Tiếng Việt",
    Icon: <TableView fontSize="small" />,
  }
];
const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnThem: "Add",
    btnEdit: "Edit",
    btnDelete: "Delete",
    btnDownload: "Download",
    btnDetail: "Detail",
    btnExport: "Export",
    btnBill: "Extra Bill",
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnThem: "Thêm",
    btnEdit: "Sửa",
    btnDelete: "Xóa",
    btnDownload: "Tải file",
    btnDetail: "Chi tiết",
    btnExport: "Xuất",
    btnBill: "Quyết toán",
  };


const buttonUpdateStatuses = [
  {
    ID: 0,
    Label: "Đang xử lý",
    LabelEN: "Doing",
    Icon: <PrecisionManufacturingIcon />
  },
  {
    ID: 1,
    Label: "Đã hoàn thành",
    LabelEN: "Done",
    Icon: <DoneAllIcon color='primary' />
  },
  {
    ID: 2,
    Label: "Đóng hồ sơ",
    LabelEN: "Closed",
    Icon: <LockIcon color="error" />
  }
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "MaYeuCau", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class POPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = [
      {
        name: "SoPhieuGV",
        type: "string",
        defaultWidth: 120,
        headerAlign: "center",
        header: englishUI ? "PO No." : "Số PGV",
        render: ({ data }) => data.SoPhieuGV
      },
      {
        name: "TenCongTy",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Contractor" : "Đơn vị SC",
        render: ({ data }) => <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{data.TenCongTy}</div>
      },
      {
        name: "YeuCau",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Subject" : "Yêu cầu",
        render: ({ data }) => <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{data.YeuCau}</div>
      },
      {
        name: "NgayGV",
        type: "string",
        defaultWidth: 90,
        headerAlign: "center",
        header: englishUI ? "Date" : "Ngày giao việc",
        render: ({ data }) => data.NgayGV ? moment(data.NgayGV).format("DD/MM/YYYY") : null
      },
      {
        name: "TenLoaiHinhPO",
        type: "string",
        defaultWidth: 100,
        headerAlign: "center",
        header: "Loại hình",
        render: ({ data }) => data.TenLoaiHinhPO
      },
      {
        name: "TenTrangThai",
        type: "string",
        defaultWidth: 100,
        headerAlign: "center",
        header: englishUI ? "Status" : "Tình trạng",
        render: (props) => <MenuButtons
          fullWidth
          variant="outlined"
          englishUI={englishUI}
          title={props.value}
          SubActions={buttonUpdateStatuses}
          onClick={(subButton) => {
            this.doUpdateStatus(props.data.HangMucID, subButton.ID);
          }}
        />
      },
      {
        name: "NgayDuToan",
        type: "string",
        defaultWidth: 100,
        headerAlign: "center",
        header: "Ngày DT",
        render: ({ data }) => data.NgayDuToan ? moment(data.NgayDuToan).format("DD/MM/YYYY") : null
      },
      {
        name: "TruocThue_DT",
        type: "string",
        defaultWidth: 110,
        headerAlign: "center",
        header: "Số tiền DT",
        textAlign: "right",
        render: ({ data }) => <NumericFormat value={data.TruocThue_DT ?? ""} displayType="text" thousandSeparator="," />
      },
      {
        name: "MaHangMuc",
        type: "string",
        defaultWidth: 140,
        headerAlign: "center",
        header: "ReS No.",
        render: ({ data }) => data.MaHangMuc
      },
      {
        name: "NgayQuyetToan",
        type: "string",
        defaultWidth: 100,
        headerAlign: "center",
        header: "Ngày QT",
        render: ({ data }) => data.NgayQuyetToan ? moment(data.NgayQuyetToan).format("DD/MM/YYYY") : null
      },
      {
        name: "TruocThue_QT",
        type: "string",
        defaultWidth: 110,
        headerAlign: "center",
        header: "Số tiền QT",
        textAlign: "right",
        render: ({ data }) => <NumericFormat value={data.TruocThue_QT ?? ""} displayType="text" thousandSeparator="," />
      },
      {
        name: "HoaDon_So",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Invoice No." : "Số hóa đơn",
      },
      {
        name: "HangMucID",
        type: "number",
        defaultWidth: 110,
        headerAlign: "center",
        header: "PO ID",
        render: ({ data }) => data.HangMucID && `P${data.HangMucID}`
      }
    ];
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [{ value: 0, label: "Doing" }, { value: 1, label: "Completed" }],
        DM_Docking: [{ value: 0, label: "No" }, { value: 1, label: "Yes" }]
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            PageIndex: 1,
            PageSize: 25,
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
            //danh sach cac tieu chi tim kiem
            Nam: new Date().getFullYear(),
            IsKetThuc: null,
            IsDocking: null,
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      hasQuyen: global.hasRoles("R261")
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_PhieuGV_Search",
          ThamSo: {
            ...this.state.after,
            ShipID: this.props.ShipData.ShipID,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  edit() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_PhieuGV_Get",
        ThamSo: {
          HangMucID: this.state.rowSelected.HangMucID,
        },
      })
      .then((response) => {
        let data = {
          edittingRecord: {
            ...response.CP_PhieuGV[0],
            Attachs: response.CP_PhieuGV_Att
          },
          open_edit: true
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  getFileID() {
    try {
      let dinhKem = JSON.parse(this.state.rowSelected.DinhKem);
      return Array.isArray(dinhKem) && dinhKem.length > 0 ? dinhKem[0] : null;
    } catch (ex) {
      console.warn(ex);
    }

    return null;
  }

  doExport(exportFormat, language) {
    server
      .post("Chiphi/DoRequest", {
        Function: "Proc_CP_PhieuGV_Export",
        ThamSo: {
          HangMucID: this.state.rowSelected.HangMucID,
          ExportFormat: exportFormat,
          Form: language,
          Authorization: server.getToken()
        },
      })
      .then((response) => {
        const decoded = Buffer.from(response.CP_PhieuGV[0].ExcelData, "base64");
        const blob = new Blob([decoded]);
        myLib.downloadBlob(blob, exportFormat == "Excel" ? "CP_PhieuGV.xlsx" : "CP_PhieuGV.docx");
      })
      .catch((error) => {
        showError(error);
      });
  }

  doUpdateStatus(HangMucID, TrangThaiGV) {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_PhieuGV_UpdateTrangThai",
        ThamSo: {
          "HangMucID": HangMucID,
          "TrangThaiGV": TrangThaiGV
        },
      })
      .then(() => {
        this.doSearch();
      })
      .catch((error) => {
        showError(error);
      });
  }

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.Nam}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 2020, max: new Date().getFullYear() } }}
                value={this.state.after.Nam}
                onChange={(e) => {
                  this.saveField("Nam", +e.target.value);
                }} />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.IsKetThuc}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Complete}
                optionLabel="label"
                optionValue="value"
                value={this.state.DanhMuc.DM_Complete.find(c => c.value == this.state.after.IsKetThuc)}
                onChange={(item) => {
                  this.saveField("IsKetThuc", item.value);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.IsDocking}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                id={"IsDocking"}
                key={"IsDocking"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Docking}
                optionLabel="label"
                optionValue="value"
                value={this.state.DanhMuc.DM_Docking.find(c => c.value == this.state.after.IsDocking)}
                onChange={(item) => {
                  this.saveField("IsDocking", item.value);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="SoPhieuGV"

              minRowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" });
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                    open_detail_PO: true
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          {this.state.hasQuyen && <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected || this.state.rowSelected.TrangThaiGV == 1}
                  onClick={() => {
                    this.edit();
                  }}
                >
                  {buttonLabels.btnEdit}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected || this.state.rowSelected.TrangThaiGV == 1}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected || this.getFileID() == null}
                  href={server.getRequest(`ChiPhi/GetFile?fileID=${this.getFileID()}`)}
                  target="_blank"
                >
                  {buttonLabels.btnDownload}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ open_detail_PO: true });
                  }}
                >
                  {buttonLabels.btnDetail}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <MenuButtons
                  fullWidth
                  englishUI={englishUI}
                  title={buttonLabels.btnExport}
                  SubActions={buttonExports}
                  disabled={!this.state.rowSelected}
                  onClick={(subButton) => {
                    this.doExport(subButton.ID, subButton.Form);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => this.setState({ showExtraBill: true })}
                >
                  {buttonLabels.btnBill}
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>

        {this.state.open_edit ? (
          <POEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa: " + this.state.rowSelected.SoPhieuGV + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_PhieuGV_Delete",
                      ThamSo: {
                        HangMucID: this.state.rowSelected.HangMucID,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.open_detail_PO ? (
          <PODetailDialog
            open={true}
            HangMucID={this.state.rowSelected.HangMucID}
            TrangThaiGV={this.state.rowSelected.TrangThaiGV}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ open_detail_PO: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showExtraBill ? (
          <ExtraBillPage
            open={true}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ showExtraBill: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(POPage);
