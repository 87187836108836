import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, OutlinedInput, Stack } from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import { handleServerError } from "../lib/MyLib";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
const server = require("../lib/server");

//Xu ly 1 file attach
export function MultiFileAttachControl({
  attachs,
  onChange,
  accept,
  deleteFileAction,
}) {
  let list = attachs && attachs.length ? attachs : [{}]; //mang trong'
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false); //hien thi form xac nhan xoa file
  const [deleting, setDeleting] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState(null);
  return (
    <Stack direction="column">
      {list.map((data, index) => (<>
        {<Stack direction={"row"} alignItems={"center"}>
          <OutlinedInput
            fullWidth
            readOnly
            value={data.FileName ? data.FileName : ""}
          />

          <Dropzone
            maxFiles={10}
            minSize={1}
            validator={(file) => {
              return false;
            }}
            accept={
              accept
                ? accept
                : {
                  "application/pdf": [".pdf"],
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
                }
            }
            onDrop={(acceptedFiles) => {
              if (onChange) {
                onChange(acceptedFiles, index);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <IconButton>
                    <CloudUploadIcon
                      fontSize="small"
                      color="info"
                    ></CloudUploadIcon>
                  </IconButton>
                </div>
              </section>
            )}
          </Dropzone>

          {data.FileID ? (
            <IconButton
              onClick={() =>
                window.open(
                  server.getRequest("ChiPhi/GetFile?FileID=" + data.FileID),
                  "_blank"
                )
              }
            >
              <VisibilityIcon fontSize="small" color="success"></VisibilityIcon>
            </IconButton>
          ) : null}

          {data.FileName ? (
            <IconButton>
              <DeleteForeverIcon
                fontSize="small"
                color="error"
                onClick={() => {
                  if (data.FileID) {
                    //xoa luon trong CSDL
                    setShowConfirmDelete(true);
                    setDeletingItem({ FileID: data.FileID, Index: index });
                  } else if (onChange) {
                    onChange(null, index); //remove
                  }
                }}
              ></DeleteForeverIcon>
            </IconButton>
          ) : null}
        </Stack>}
      </>
      ))}
      {showConfirmDelete ? (
        <DeleteConfirmDialog
          open="true"
          title={"Xác nhận"}
          message={"Bạn muốn xóa file ?"}
          isDeleting={deleting}
          handleCloseAction={() => setShowConfirmDelete(false)}
          handleDeleteAction={() => {
            setDeleting(true);
            deleteFileAction(deletingItem.FileID)
              .then(() => {
                setDeleting(false);
                setShowConfirmDelete(false);
                onChange(null, deletingItem.Index); //remove file
              })
              .catch((e) => {
                handleServerError(e);
                setDeleting(false);
              });
          }}
        />
      ) : null}
    </Stack>
  );
}
