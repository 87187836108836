
import "@inovua/reactdatagrid-enterprise/index.css";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography
} from "@mui/material";
import Tab from '@mui/material/Tab';
import { withStyles } from "@mui/styles";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import { formStyles } from "../../components/common";
import AttachmentDocumentsTab from "./AttachmentDocumentsTab";
import ContractorEvaluationTab from "./ContractorEvaluationTab";
import CostEstimationTab from "./CostEstimationTab";
import FinalSettlementTab from "./FinalSettlementTab";
import InvoiceTab from "./InvoiceTab";
import PODetailTab from "./PODetailTab";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Purchase order detail" : "Chi tiết đợt sửa chữa";
const buttonLabels = englishUI
  ? {
    btnClose: "Close"
  }
  : {
    btnClose: "Thoát"
  };

const tabContentStyles = {
  padding: "12px",
  height: "calc(100% - 36px)",
  overflow: "auto"
};

class PODetailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "PO",
      QuyetToanID: null,
      SoPhieuGV: null,
      TruocThue: null,
      SauThue: null
    };
  }

  componentDidMount() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: this.props.HangMucID,
        }
      }).then(response => {
        if (Array.isArray(response.CP_QuyetToanSC) && response.CP_QuyetToanSC.filter(qt => qt.IsQuyetToan).length > 0) {
          this.setState({
            QuyetToanID: response.CP_QuyetToanSC.filter(qt => qt.IsQuyetToan)[0].QuyetToanID,
            TruocThue: response.CP_QuyetToanSC.filter(qt => qt.IsQuyetToan)[0].TruocThue,
            SauThue: response.CP_QuyetToanSC.filter(qt => qt.IsQuyetToan)[0].SauThue,
          });
        }

        if (Array.isArray(response.CP_PhieuGV) && response.CP_PhieuGV.length == 1) {
          this.setState({ SoPhieuGV: response.CP_PhieuGV[0].SoPhieuGV });
        }
      });
  }

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  changeTab = (_event, tabName) => {
    this.setState({ selectedTab: tabName });
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}{this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (<></>)}
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent sx={{ padding: 0, height: "calc(100vh - 50px)", overflow: "auto" }}>
          <TabContext value={this.state.selectedTab}>
            <TabPanel value="PO" sx={tabContentStyles}>
              <PODetailTab HangMucID={this.props.HangMucID} />
            </TabPanel>
            <TabPanel value="QT" sx={tabContentStyles}>
              <FinalSettlementTab HangMucID={this.props.HangMucID} ShipData={this.props.ShipData} />
            </TabPanel>
            <TabPanel value="DT" sx={tabContentStyles}>
              <CostEstimationTab HangMucID={this.props.HangMucID} />
            </TabPanel>
            <TabPanel value="Attach" sx={tabContentStyles}>
              <AttachmentDocumentsTab HangMucID={this.props.HangMucID} ShipData={this.props.ShipData} />
            </TabPanel>
            <TabPanel value="Invoice" sx={tabContentStyles}>
              <InvoiceTab QuyetToanID={this.state.QuyetToanID} TruocThue={this.state.TruocThue} SauThue={this.state.SauThue} />
            </TabPanel>
            <TabPanel value="ContractorEvaluation" sx={tabContentStyles}>
              <ContractorEvaluationTab
                HangMucID={this.props.HangMucID}
                QuyetToanID={this.state.QuyetToanID}
                SoPhieuGV={this.state.SoPhieuGV}
                ShipData={this.props.ShipData} />
            </TabPanel>
            <TabList onChange={this.changeTab} variant="fullWidth">
              <Tab label="Purchase order" value="PO" />
              <Tab label="Final Bill" value="QT" />
              <Tab label="Quotation" value="DT" />
              <Tab label="Documents" value="Attach" />
              {this.state.QuyetToanID && <Tab label="Invoice" value="Invoice" />}
              {[1, 2].includes(this.props.TrangThaiGV) && this.state.QuyetToanID != null && <Tab label="Contractor Evaluation" value="ContractorEvaluation" />}
            </TabList>
          </TabContext>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(formStyles, { withTheme: true })(PODetailDialog);