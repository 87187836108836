import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  FormLabel,
  Grid
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import { formStyles, showError } from "../../components/common";
import RepairSpecificationEditDialog from "../RepairSpecificationEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const fieldLabels_EN = {
  SoPhieuGV: "PO No.:",
  NgayGV: "Date:",
  TenCongTy: "To:",
  GiamDoc: "Attn:",
  PhongYeuCau: "From:",
  YeuCau: "Subject:",
  MoTa: "Description:",
  GhiChu: "Remark:",
  NguoiDeNghi: "Created by:",
  TruongPhong: "Approved by:",
  MaHangMuc: "Repair Items:",
  TenLoaiHinhPO: "Type:",
};
const fieldLabels_VN = {
  SoPhieuGV: "Số phiếu GV:",
  NgayGV: "Ngày:",
  TenCongTy: "Gửi:",
  GiamDoc: "Người nhận:",
  PhongYeuCau: "Từ:",
  YeuCau: "Yêu cầu:",
  MoTa: "Mô tả:",
  GhiChu: "Ghi chú:",
  NguoiDeNghi: "Người đề nghị:",
  TruongPhong: "Người duyệt:",
  MaHangMuc: "Mã HMSC:",
  TenLoaiHinhPO: "Loại:",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
const buttonLabels = englishUI
  ? {
    btnClose: "Close",
    btnEdit: "Edit"
  }
  : {
    btnClose: "Thoát",
    btnEdit: "Chỉnh sửa"
  };


class PODetailTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "HMSC",
      HangMucSC: [],
      after: {
        SoPhieuGV: null,
        NgayGV: null,
        TenCongTy: null,
        GiamDoc: null,
        PhongYeuCau: null,
        YeuCau: null,
        MoTa: null,
        GhiChu: null,
        NguoiDeNghi: null,
        TruongPhong: null,
        MaHangMuc: null,
        TenLoaiHinhPO: null,
        ...this.props.ShipData,
      },
    };
  }

  componentDidMount() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_PhieuGV_Get",
        ThamSo: {
          HangMucID: this.props.HangMucID,
        },
      })
      .then((response) => {
        let data = {
          HangMucSC: Array.isArray(response.CP_HangMucSC) ? response.CP_HangMucSC : [],
          after: {
            ...this.state.after,
            ...response.CP_PhieuGV[0]
          },
        };
        this.setState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  changeTab = (_event, tabName) => {
    this.setState({ selectedTab: tabName });
  };

  edit(HangMucID) {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: HangMucID
        },
      })
      .then((response) => {
        let data = {
          edittingRecord: {
            ...response.CP_HangMucSC[0],
            NoiDung: response.CP_HangMucSC_ND.map(item => {
              return {
                ...item,
                MaYeuCau: item.MaYeuCau ?? item.MaYeuCauSC,
                MaNoiDung: item.MaNoiDung ?? item.MaNoiDungYC
              }
            })
          },
          open_edit_HMSC: true
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (<>
      <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "SoPhieuGV",
              })
            }
          >
            {fieldLabels.SoPhieuGV}
          </FormLabel>
        </Grid>
        <Grid item xs={4}>
          {this.state.after.SoPhieuGV}
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "NgayGV",
              })
            }
          >
            {fieldLabels.NgayGV}
          </FormLabel>
        </Grid>
        <Grid item xs={4}>
          {this.state.after.NgayGV ? moment(this.state.after.NgayGV).format("DD/MM/YYYY") : ""}
        </Grid>
      </Grid>
      <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "TenCongTy",
              })
            }
          >
            {fieldLabels.TenCongTy}
          </FormLabel>
        </Grid>
        <Grid item xs={22}>
          {this.state.after.TenCongTy}
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "GiamDoc",
              })
            }
          >
            {fieldLabels.GiamDoc}
          </FormLabel>
        </Grid>
        <Grid item xs={4}>
          {this.state.after.GiamDoc}
        </Grid>
      </Grid>
      <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "PhongYeuCau",
              })
            }
          >
            {fieldLabels.PhongYeuCau}
          </FormLabel>
        </Grid>
        <Grid item xs={22}>
          {this.state.after.PhongYeuCau}
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "YeuCau",
              })
            }
          >
            {fieldLabels.YeuCau}
          </FormLabel>
        </Grid>
        <Grid item xs={22}>
          {this.state.after.YeuCau}
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "MoTa",
              })
            }
          >
            {fieldLabels.MoTa}
          </FormLabel>
        </Grid>
        <Grid item xs={22}>
          {this.state.after.MoTa}
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "GhiChu",
              })
            }
          >
            {fieldLabels.GhiChu}
          </FormLabel>
        </Grid>
        <Grid item xs={22}>
          {this.state.after.GhiChu}
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "MaHangMuc",
              })
            }
          >
            {fieldLabels.MaHangMuc}
          </FormLabel>
        </Grid>
        {this.state.HangMucSC.map(item => <Grid item xs={4}>
          {item.MaHangMuc}
          <Button
            variant="contained"
            sx={{ marginLeft: "10px" }}
            onClick={() => {
              this.edit(item.HangMucID);
            }}
          >
            {buttonLabels.btnEdit}
          </Button>
        </Grid>)}
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "TenLoaiHinhPO",
              })
            }
          >
            {fieldLabels.TenLoaiHinhPO}
          </FormLabel>
        </Grid>
        <Grid item xs={4}>
          {this.state.after.TenLoaiHinhPO}
        </Grid>
      </Grid>

      <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
        <Grid item xs={24}>
          <hr />
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "NguoiDeNghi",
              })
            }
          >
            {fieldLabels.NguoiDeNghi}
          </FormLabel>
        </Grid>
        <Grid item xs={4}>
          {this.state.after.NguoiDeNghi}
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={2}>
          <FormLabel
            onClick={() =>
              this.setState({
                show_thongtin_field: true,
                Show_FieldName: "TruongPhong",
              })
            }
          >
            {fieldLabels.TruongPhong}
          </FormLabel>
        </Grid>
        <Grid item xs={4}>
          {this.state.after.TruongPhong}
        </Grid>
      </Grid>

      {this.state.open_edit_HMSC ? (
        <RepairSpecificationEditDialog
          open={true}
          edittingRecord={this.state.edittingRecord}
          ShipData={this.props.ShipData}
          close={() => {
            this.setState({ open_edit_HMSC: false });
          }}
        />
      ) : null}
    </>);
  }
}

export default withStyles(formStyles, { withTheme: true })(PODetailTab);