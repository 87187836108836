import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../../components/MyDateEditor";
import MyNumberEditor from "../../components/MyNumberEditor";
import { formStyles, handleServerError, showError } from "../../components/common";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
const buttonLabels = englishUI
  ? {
    btnImport: "Import Excel file",
    btnSave: "Save",
    btnViewPrint: "View/Print"
  }
  : {
    btnImport: "Nhập từ file Excel",
    btnSave: "Lưu",
    btnViewPrint: "Xem/In"
  };

const fieldLabels_EN = {
  NguoiCham: "Evaluator:",
  NgayCham: "Evaluate date:",
  NguoiDuyet: "Approved by:",
  NgayDuyet: "Approved date:"
};
const fieldLabels_VN = {
  NguoiCham: "Người chấm:",
  NgayCham: "Ngày chấm:",
  NguoiDuyet: "Người duyệt:",
  NgayDuyet: "Ngày duyệt:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class ContractorEvaluationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_ChamDiem: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        QuyetToanID: this.props.QuyetToanID,
        NguoiCham: null,
        NgayCham: new Date(),
        NguoiDuyet: null,
        NgayDuyet: new Date(),
        DanhSach: []
      },
      showViewPrintButton: false
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_ChamDiem", () => { this.loadData(); });
  }

  loadData() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_ChamDiem_Get",
        ThamSo: {
          QuyetToanID: this.props.QuyetToanID
        }
      }).then(response => {
        if (Array.isArray(response.CP_ChamDiem)) {
          if (response.CP_ChamDiem.length > 0) {
            this.setState({ showViewPrintButton: true });
          }

          let NguoiCham = response.CP_ChamDiem.length > 0 ? response.CP_ChamDiem[0].NguoiCham : null;
          let NgayCham = response.CP_ChamDiem.length > 0 ? response.CP_ChamDiem[0].NgayCham : new Date();
          let NguoiDuyet = response.CP_ChamDiem.length > 0 ? response.CP_ChamDiem[0].NguoiDuyet : null;
          let NgayDuyet = response.CP_ChamDiem.length > 0 ? response.CP_ChamDiem[0].NgayDuyet : new Date();

          this.state.DanhMuc.DM_ChamDiem.forEach(tc => {
            if (!response.CP_ChamDiem.some(item => item.TieuChi == tc.TieuChi)) {
              response.CP_ChamDiem.push({
                TieuChi: tc.TieuChi,
                Rate: tc.Rate
              });
            }
          });

          this.saveField("DanhSach", response.CP_ChamDiem, () => {
            let s = this.state;

            s.after.NguoiCham = NguoiCham;
            s.after.NgayCham = NgayCham;
            s.after.NguoiDuyet = NguoiDuyet;
            s.after.NgayDuyet = NgayDuyet;

            s.dataChanged = true;
            s.saving = false;
            this.setState(s);
          });
        }
      }).catch((error) => {
        showError(error);
      });
  }

  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }

  saveField = (fieldName, value, callback) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    s.saving = false;
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };

  validate_Data(data) {
    var ret = myLib.validateFields(data, [
      "NguoiCham",
      "NgayCham",
      "NguoiDuyet",
      "NgayDuyet",
    ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

    ret.error = Object.keys(ret).length > 0;

    ret.DanhSach = this.state.DanhMuc.DM_ChamDiem.map((tc, index) => {
      return {
        Rate: data.DanhSach.length < index + 1 ||
          myLib.isEmpty(data.DanhSach[index].Rate) ||
          data.DanhSach[index].Rate < 1 ||
          !Number.isInteger(data.DanhSach[index].Rate),
        Point: data.DanhSach.length < index + 1 ||
          myLib.isEmpty(data.DanhSach[index].Point) ||
          data.DanhSach[index].Point < 1 ||
          data.DanhSach[index].Point > 10 ||
          !Number.isInteger(data.DanhSach[index].Point)
      };
    });

    ret.error = ret.error || ret.DanhSach.some(tc => tc.Rate || tc.Point);
    return ret;
  }

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after, this.state.Attachs);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_CP_ChamDiem_Update",
            ThamSo: {
              ...this.state.after
            },
          })
          .then(() => {
            toast.success("Saved data successfully.");
            this.setState({ showViewPrintButton: true });
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  doViewAndPrint() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_DM_ReportForm_GetOne",
        ThamSo: {
          ReportFormCode: "CHAMDIEM_PO"
        }
      }).then(response1 => {
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_DM_ReportFile_GetBy",
            ThamSo: {
              ReportFormID: response1.DM_ReportForm[0].ReportFormID
            }
          }).then(response2 => {
            var thamso = {
              ...response1.DM_ReportForm[0],
              ...response2.DM_ReportFile[0],
              "SoPhieuGV": this.props.SoPhieuGV
            };
            var ps = encodeURIComponent(JSON.stringify(thamso));
            window.open(
              server.getServer() +
              "pages/ShowReport.aspx?" +
              "thamso_json=" +
              ps +
              "&_t=" +
              new Date().getTime() +
              "&sessionId=" +
              global.User.RandomID,
              "_blank"
            );
          })
      }).catch(error => {
        this.setState({ saving: false });
        handleServerError(error);
      })
  }

  //thuc hien import file
  doImport(files) {
    if (Array.isArray(files) && files.length == 1) {
      try {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          const json = {
            "FileName": files[0].name,
            "Content": Buffer.from(text).toString("base64")
          };
          this.doUpload(json);
        };
        fr.onerror = (err) => showError(err);
        fr.readAsArrayBuffer(files[0]);
      } catch (ex) {
        showError(ex);
      }
    }
  }
  //upload du lieu report
  doUpload(json) {
    this.setState({ saving: true });
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_ChamDiem_ImportExcel",
        ThamSo: json,
      })
      .then((response) => {
        if (Array.isArray(response.CP_ChamDiem)) {
          this.state.DanhMuc.DM_ChamDiem.forEach(tc => {
            if (!response.CP_ChamDiem.some(item => item.TieuChi == tc.TieuChi)) {
              response.CP_ChamDiem.push({
                TieuChi: tc.TieuChi
              });
            }
          });

          this.saveField("DanhSach", response.CP_ChamDiem);
        }
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }

  render() {
    return <>
      <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={16}>
          <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
            <Grid item xs={1}><b>No</b></Grid>
            <Grid item xs={6}><b>Evaluated Areas</b></Grid>
            <Grid item xs={2}><b>Rate</b></Grid>
            <Grid item xs={2}><b>Point</b></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}><b>No</b></Grid>
            <Grid item xs={6}><b>Evaluated Areas</b></Grid>
            <Grid item xs={2}><b>Rate</b></Grid>
            <Grid item xs={2}><b>Point</b></Grid>
            <Grid item xs={1}></Grid>
            {this.state.DanhMuc.DM_ChamDiem.map((item, index) => <>
              <Grid item xs={1}>{index + 1}.</Grid>
              <Grid item xs={6}>{item.TenTieuChi}</Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  decimalScale={0}
                  inputProps={{ min: 1, max: 10 }}
                  value={this.state.after.DanhSach.find(tc => tc.TieuChi == item.TieuChi)?.Rate}
                  onChange={value => {
                    let s = { after: { ...this.state.after } };
                    s.after.DanhSach[index] = {
                      TieuChi: item.TieuChi,
                      Rate: value,
                      Point: s.after.DanhSach.length > index ? s.after.DanhSach[index].Point : 1
                    };
                    s.dataChanged = true;
                    s.saving = false;
                    this.setState(s);
                  }}
                  error={
                    Array.isArray(this.state.validateStatus.DanhSach) &&
                    this.state.validateStatus.DanhSach[index]?.Rate == true
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  decimalScale={0}
                  inputProps={{ min: 1, max: 10 }}
                  value={this.state.after.DanhSach.find(tc => tc.TieuChi == item.TieuChi)?.Point}
                  onChange={value => {
                    let s = { after: { ...this.state.after } };
                    s.after.DanhSach[index] = {
                      TieuChi: item.TieuChi,
                      Rate: s.after.DanhSach.length > index ? s.after.DanhSach[index].Rate : 1,
                      Point: value
                    };
                    s.dataChanged = true;
                    s.saving = false;
                    this.setState(s);
                  }}
                  error={
                    Array.isArray(this.state.validateStatus.DanhSach) &&
                    this.state.validateStatus.DanhSach[index]?.Point == true
                  }
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </>)}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
            <Grid item xs={6}>
              <FormLabel>
                {fieldLabels.NguoiCham}
              </FormLabel>
            </Grid>
            <Grid item xs={18}>
              <OutlinedInput
                fullWidth
                value={this.state.after.NguoiCham}
                onChange={(event) => {
                  this.saveField("NguoiCham", event.target.value);
                }}
                error={this.state.validateStatus.NguoiCham !== undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>
                {fieldLabels.NgayCham}
              </FormLabel>
            </Grid>
            <Grid item xs={18}>
              <MyDateEditor
                fullWidth
                value={this.state.after.NgayCham}
                onChange={(value) => {
                  this.saveField("NgayCham", value);
                }}
                error={this.state.validateStatus.NgayCham !== undefined}
              />
            </Grid>
            <Grid item xs={24}>
              <hr />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>
                {fieldLabels.NguoiDuyet}
              </FormLabel>
            </Grid>
            <Grid item xs={18}>
              <OutlinedInput
                fullWidth
                value={this.state.after.NguoiDuyet}
                onChange={(event) => {
                  this.saveField("NguoiDuyet", event.target.value);
                }}
                error={this.state.validateStatus.NguoiDuyet !== undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>
                {fieldLabels.NgayDuyet}
              </FormLabel>
            </Grid>
            <Grid item xs={18}>
              <MyDateEditor
                fullWidth
                value={this.state.after.NgayDuyet}
                onChange={(value) => {
                  this.saveField("NgayDuyet", value);
                }}
                error={this.state.validateStatus.NgayDuyet !== undefined}
              />
            </Grid>
            <Grid item xs={24}>
              <hr />
            </Grid>
            <Grid item xs={24}>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Dropzone
                  maxFiles={1}
                  minSize={1}
                  accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] }}
                  onDrop={(acceptedFiles) => this.doImport(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth color="success" variant="contained">
                          {buttonLabels.btnImport}
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <Button
                  color="success"
                  variant="contained"
                  disabled={!this.state.dataChanged || this.state.saving}
                  onClick={() => this.doSave()}
                >
                  {buttonLabels.btnSave}
                </Button>
                {this.state.showViewPrintButton && <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.doViewAndPrint()}
                >
                  {buttonLabels.btnViewPrint}
                </Button>}
              </Stack >
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  }
}


export default withStyles(formStyles, { withTheme: true })(ContractorEvaluationTab);