import {
  AppBar,
  Button,
  Dialog,
  FormLabel,
  Grid,
  OutlinedInput,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import ContractorEvaluationUpdateDialog from "./ContractorEvaluationUpdateDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    Nam: "Year:"
  }
  : {
    Nam: "Nam:"
  };

const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnUpdate: "Update"
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnUpdate: "Cập nhật"
  };
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "TenCongTy", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox
const columns_grid = [
  {
    name: "STT",
    type: "number",
    defaultWidth: 50,
    headerAlign: "center",
    header: englishUI ? "No" : "STT",
  },
  {
    name: "TenCongTy",
    type: "string",
    defaultFlex: 1,
    headerAlign: "center",
    header: englishUI ? "Shipyard/Sub-Contractor" : "Nhà thầu"
  },
  {
    name: "AcceptPoint",
    type: "number",
    defaultWidth: 150,
    headerAlign: "center",
    header: "Accept Point",
  },
  {
    name: "Approved",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: "Approved",
    render: ({ data }) => data.Approved ? "Yes" : "No"
  },
  {
    name: "TotalPoint",
    type: "number",
    defaultWidth: 150,
    headerAlign: "center",
    header: "Total Point",
  },
  {
    name: "Remark",
    type: "string",
    defaultFlex: 1,
    headerAlign: "center",
    header: englishUI ? "Remark" : "Ghi chú"
  },
  {
    name: "NgayCN",
    type: "string",
    defaultWidth: 140,
    headerAlign: "center",
    header: "Update time",
    render: ({ data }) =>
      data.NgayCN ? moment(data.NgayCN).format("DD/MM/YYYY HH:mm:ss") : null,
  },
];
class ContractorEvaluationPage extends React.Component {
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);

    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {}, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after: {
        Nam: new Date().getFullYear()
      }
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }

  loadData = () => {
    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_ChamDiemNam_Get",
          ThamSo: {
            ...this.state.after
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.CP_ChamDiemNam,
              count: response.CP_ChamDiemNam.Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.CP_ChamDiemNam,
            count: response.CP_ChamDiemNam.Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };

  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData();
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              Contractor Evaluation
            </Typography>
          </Stack>
        </AppBar>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.Nam}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <OutlinedInput
                fullWidth
                type="number"
                value={this.state.after.Nam}
                onChange={e => {
                  this.saveField("Nam", +e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.setState({ showUpdateForm: true });
                }}
              >
                {buttonLabels.btnUpdate}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.props.close();
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: 400,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={columns_grid}
              pagination={false}
              dataSource={this.state.search_dataSource}
              idProperty="STT"
              
              minRowHeight={30}
            ></ReactDataGrid>
          </Grid>
        </Grid>

        {this.state.showUpdateForm ? (
          <ContractorEvaluationUpdateDialog
            open={true}
            Nam={this.state.after.Nam}
            close={(saved) => {
              this.setState({ showUpdateForm: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ContractorEvaluationPage);
