/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import { Shower } from "@mui/icons-material";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "GRAPH DATA" : "DỮ LIỆU ĐỒ THỊ";
const fieldLabels = englishUI
  ? {
      GraphCode: "Code:",
      GraphValues: "Graph X-Y values: (ex: 100;200)",
      TestValue: "Test input:",
      TestResult: "Test result:",
    }
  : {
      GraphCode: "Mã số:",
      GraphValues: "Giá trị X-Y: (vd: 100;200)",
      TestValue: "Tra cứu thử:",
      TestResult: "Kết quả thử:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
      btnTest: "Test",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
      btnTest: "Thử",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["GraphCode", "GraphValues"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version

  return ret;
}
////////////////////////--------------/////////////////////////////////////////
export default class GraphEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        GraphCode: "",
        GraphValues: "",
        TestValue: null,
        TestResult: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //load du lieu khi form load
  loadDanhMuc() {
    if (this.props.edittingRecord !== undefined) {
      this.setState({ saving: true });
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_DM_Graph_GetOne",
          ThamSo: {
            GraphCode: this.state.after.GraphCode,
          },
        })
        .then((response) => {
          //du lieu cua 1 do thi
          const list = response.DM_Graph;
          var str = "";
          list.map((item) => {
            str += (str !== "" ? "\n" : "") + item.XValue + ";" + item.YValue;
          });
          this.state.after.GraphValues = str;
          this.setState({ saving: false });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    }
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      //chuyen doi format
      var data = myLib.cloneObject(this.state.after);
      var values = data.GraphValues.split("\n"); //string
      var list = [];
      values.map((str) => {
        var ps = str.split(";");
        var x = Number(ps[0]);
        var y = Number(ps[1]);
        list.push([x, y]);
      });
      data.GraphValues = list; //thay the array number
      var action = this.props.edittingRecord ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_DM_Graph_Update",
            ThamSo: data,
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, data);
            }
            alert("Cập nhật thành công!");
            this.props.close(data);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };
  //thuc hien thu gia tri
  doTest = () => {
    try {
      this.setState({ saving: true, errorLog: "" });
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_DM_Graph_GetTest",
          ThamSo: {
            GraphCode: this.state.after.GraphCode,
            TestValue: this.state.after.TestValue,
          },
        })
        .then((response) => {
          //lay ket qua neu co
          this.state.after.TestResult = response.DM_Graph_Result[0].Result;
          this.setState({
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
          });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    } catch (ex) {
      showError(ex);
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.GraphCode}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtGraphCode"
                  fullWidth
                  autoFocus
                  readOnly={this.props.edittingRecord !== undefined}
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 20,
                  }}
                  value={this.state.after.GraphCode}
                  onChange={(event) => {
                    this.saveField(
                      "GraphCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.GraphCode !== undefined}
                />
              </Grid>
              <Grid item xs={24}>
                <FormLabel>{fieldLabels.GraphValues}</FormLabel>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="GraphValues"
                  fullWidth
                  multiline
                  inputProps={{
                    style: { height: 300, overflow: "scroll" },
                  }}
                  value={this.state.after.GraphValues}
                  onChange={(event) => {
                    this.saveField("GraphValues", event.target.value);
                  }}
                  error={this.state.validateStatus.GraphValues !== undefined}
                />
              </Grid>
              {
                this.props.edittingRecord !== undefined
              ?
              <>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.TestValue}</FormLabel>
              </Grid>

              <Grid item xs={4}>
                <MyNumberEditor
                  id="TestValue"
                  fullWidth
                  decimalPlaces={4}
                  value={this.state.after.TestValue}
                  onValueChange={(value) => {
                    this.saveField("TestValue", value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.TestResult}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="TestResult"
                  fullWidth
                  readOnly
                  value={this.state.after.TestResult}
                />
              </Grid>
              </> : null}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>
          {this.props.edittingRecord !== undefined ? (
            <Button
              onClick={() => {
                this.doTest();
              }}
              variant="contained"
            >
              {buttonLabels.btnTest}
            </Button>
          ) : null}
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
